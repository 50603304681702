import React, {useMemo} from "react";
import {Box, Button, CircularProgress, Container, Dialog, DialogContent, Fade, Stack, Typography} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import {Trans, useTranslation} from "react-i18next";
import {API_URL} from "../config";
import WithBackground from "./WithBackground";
import {Formik} from "formik";
import {createValidationSchema} from "../createValidationScheme";
import useAxios from "axios-hooks";
import {useDataStore} from "../state/state";
import {FormContent} from "./RegistrationForm/FormContent";
import {formMap} from "../assets/data/form-reale";
import {useParams} from "react-router-dom";
import ClosedSubscriptions from "./ClosedSubscriptions";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const RegistrationForm = () => {
    const {id} = useParams()
    const {t} = useTranslation()
    const {data} = useDataStore()

    const partecipazione = data?.partecipazione


    const [{_data, loading, _error}, executePost] = useAxios(
        {
            url: `${API_URL}register/${id}`, method: 'POST'
        }, {manual: true}
    )

    const [success, setSuccess] = React.useState(false)
    const [error, setError] = React.useState(null)
    const [errorValidation, setErrorValidation] = React.useState(false)

    const initialValues = () => {
        let d = {
            richiestaDocumenti: data.richiestaDocumenti || 'no',
            categoria: data.categoria,
            lang: data.lang,
            'rma-mezzi-propri': data['rma-mezzi-propri']
        }
        formMap.map(s => {
            return s.fields.filter(f => f.type !== 'note').forEach(f => {
                    let key = f.id
                    if (f.type === 'date' || f.type === 'datetime' || f.type === 'time') {
                        d[key] = data?.[key] ?? null
                    } else if (f.type === 'checkbox') {
                        d[key] = data?.[key] ?? false
                    } else {
                        d[key] = data?.[key] ?? ''
                    }
                }
            )
        })
        return d
    }

    const _initialValues = useMemo(() => {
        if (data)
            return initialValues()
        else return {}
    }, [data])

    const yupSchema = () => {
        let fields = []
        formMap.map(s => {
            return s.fields.filter(f => f.type !== 'note').forEach(f => {
                fields.push(f)
            })
        })
        let schema = createValidationSchema(fields)
        return schema
    }

    const validateSchema = yupSchema();

    const sendData = async (values) => {
        if (!partecipazione)
            executePost({
                data: {...values, partecipazione: 'si'}
            }).then(r => {
                setSuccess(true)
                setError(null)
            }).catch(e => {
                console.log("register > e:")
                setError('error')
            })
    }

    function handleValidationErrors(formik) {
        if (Object.keys(formik.errors).length > 0) {
            setErrorValidation(true)
        }
    }

    function handleCloseValidationErrors() {
        setErrorValidation(false)
    }

    return (
        <WithBackground bg={'/assets/images/bkg-reg-form.png'}>
            <Fade in>
                {
                    (!partecipazione || partecipazione === 'no')
                        ? <ClosedSubscriptions/>
                        : <Container maxWidth={'md'}>
                            <img src={'/assets/images/banner-1.png'} width={'100%'} alt={'header'}/>
                            {success ?
                                <Box mt={2}>
                                    <Typography textAlign={'center'} variant={'h5'} fontWeight={'bold'}>
                                        {t('success.message')}
                                    </Typography>
                                </Box>
                                :
                                <Box sx={{pt: 0, pb: 8}}>
                                    {partecipazione &&
                                        <Typography sx={{my: 1}} textAlign={'center'} variant={'h5'} fontWeight={'bold'}>
                                            {t('success.message')}
                                        </Typography>}
                                    <Formik
                                        validationSchema={validateSchema}
                                        initialValues={{
                                            ..._initialValues
                                        }}
                                        enableReinitialize
                                        validateOnChange={true}
                                        validateOnBlur={false}
                                        validateOnMount={true}
                                        onSubmit={values => {
                                            sendData(values)
                                        }}
                                    >
                                        {(formik) =>
                                            <form onSubmit={formik.handleSubmit}>
                                                <FormContent readOnly={!!partecipazione} formik={formik}/>
                                                <Dialog open={errorValidation} onClose={handleCloseValidationErrors}>
                                                    <DialogContent>
                                                        <Typography gutterBottom>
                                                            {t('shared.error-validation-label')}
                                                        </Typography>
                                                        {
                                                            Object.keys(formik.errors).map((field) => (
                                                                <Typography>
                                                                    <Trans i18nKey={`fields.${field}`}/>
                                                                </Typography>
                                                            ))
                                                        }
                                                    </DialogContent>
                                                </Dialog>
                                                <Box sx={{textAlign: 'center', pt: 2}}>
                                                    {loading ? <CircularProgress/> :
                                                        !partecipazione ?
                                                            <Stack alignItems={'center'} spacing={1}>
                                                                <Button variant={'contained'}
                                                                        sx={{width: 'min(20rem, 100%)', fontSize: '1.4rem'}}
                                                                        type={'submit'}
                                                                        onClick={() => handleValidationErrors(formik)}
                                                                >
                                                                    {t('shared.subscribe')}
                                                                </Button>
                                                                <Typography>{t('shared.checkFields')}</Typography>
                                                            </Stack> : null
                                                    }
                                                    {error ?
                                                        <Typography variant={'h5'} textAlign={'center'}>
                                                            {t(`shared.error`)}
                                                        </Typography> : null}
                                                </Box>
                                            </form>
                                        }
                                    </Formik>
                                </Box>}
                        </Container>
                }
            </Fade>
        </WithBackground>
    )
}

export default RegistrationForm