//export const API_URL = 'https://matilde-api-dev.plesh.co/'

//export const API_URL = "https://reale-mutua-2024-api-c7qjhnebkq-ew.a.run.app/" // PROD
export const API_URL = "https://api.weplay4real.com/" // PROD
//export const API_URL = "http://localhost:8080/"

export const mezzoTrasportoValues = [
    {
        "label": "BUS",
        "value": "BUS"
    },
    {
        "label": "MEZZI PROPRI",
        "value": "MEZZI PROPRI"
    },
    {
        "value": "MEZZI PROPRI - OPZ VIAGGIO ORGANIZZATO",
        "label": "MEZZO PROPRIO ma se preferisci venire con viaggio organizzato scrivilo nelle note o contatta la segreteria"
    },
    {
        "label": "TRENO",
        "value": "TRENO"
    },
    {
        "value": "TRENO - OPZ MEZZI PROPRI",
        "label": "TRENO ma se preferisci venire con mezzo proprio scrivilo nelle note o contatta la segreteria"
    },
    {
        "label": "VOLO",
        "value": "VOLO"
    },
    {
        "label": "No, preferisco essere autonomo e viaggiare con mezzo proprio",
        "value": "rma-mezzi-propri"
    }
]

export const hotels = {
    "ART HOTEL OLYMPIC": {
        label: "ART HOTEL OLYMPIC",
        indirizzo: "Via Verolengo 19 – 10149 Torino TO",
        map: "https://g.co/kgs/ZwU3dii"
    },
    "CONCORD": {
        label: "HOTEL CONCORD TORINO",
        indirizzo: "Via Giuseppe Luigi Lagrange 47 – 10123 Torino TO",
        map: "https://maps.app.goo.gl/i9E9FA8sM5vm1WSJ8"
    },
    "LANCASTER": {
        label: "HOTEL LANCASTER",
        indirizzo: "Corso Filippo Turati 8 – 10128 Torino TO",
        map: "https://g.co/kgs/rvYMsz1"
    },
    "NH COLLECTION PIAZZA CARLINA": {
        label: "NH COLLECTION PIAZZA CARLINA",
        indirizzo: "P.za Carlo Emanuele II 15 – 10123 Torino TO",
        map: "https://g.co/kgs/ZxbfBez"
    },
    "NH COLLECTION SANTO STEFANO": {
        label: "NH COLLECTION SANTO STEFANO",
        indirizzo: "Via Porta Palatina 19 – 10122 Torino TO",
        map: "https://g.co/kgs/cW3wjCd"
    },
    "NH LINGOTTO CONGRESS": {
        label: "NH LINGOTTO CONGRESS",
        indirizzo: "Via Nizza 262 – 10126 Torino TO, Centro Commerciale Lingotto",
        map: "https://g.co/kgs/UcU9HDH"
    },
    "NH TORINO CENTRO": {
        label: "NH TORINO CENTRO",
        indirizzo: "C.so Vittorio Emanuele II 104 – 10121 Torino TO",
        map: "https://g.co/kgs/eXbESoZ"
    },
    "PRINCIPI DI PIEMONTE": {
        label: "PRINCIPI DI PIEMONTE | UNA ESPERIENZE",
        indirizzo: "Via Piero Gobetti 15 – 10123 Torino TO",
        map: "https://g.co/kgs/QhZ7Ybd"
    },
    "PRINCIPE DI TORINO": {
        label: "HOTEL PRINCIPE DI TORINO",
        indirizzo: "Corso Moncalieri 85 – 10133 Torino TO",
        map: "https://g.co/kgs/X7qefKX"
    },
    "ROYAL HOTEL": {
        label: "HOTEL ROYAL",
        indirizzo: "Corso Regina Margherita 249 – 10144 Torino TO",
        map: "https://g.co/kgs/yLz2pt4"
    },
    "STARHOTEL MAJESTIC": {
        label: "STARHOTEL MAJESTIC",
        indirizzo: "C.so Vittorio Emanuele II 54 – 10123 Torino TO",
        map: "https://g.co/kgs/dQbfYeL"
    },
    "TURIN PALACE": {
        label: "TURIN PALACE",
        indirizzo: "Via Paolo Sacchi 8 – 10128 Torino TO",
        map: "https://g.co/kgs/e5BDyMh"
    }
}