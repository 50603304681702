import {Trans, useTranslation} from "react-i18next";
import {find} from "lodash";
import {mezzoTrasportoValues} from "../../config";
import {Stack, Typography} from "@mui/material";
import React from "react";

const RecapConvo = ({userData}) => {
    const dataPartenza = new Date(userData.dataPartenza)?.toLocaleDateString()
    const dataRitorno = new Date(userData.dataRitorno)?.toLocaleDateString()

    const {t} = useTranslation()
    const mezzo = find(mezzoTrasportoValues, ['value', userData.mezzoTrasporto])
    const categoria = userData.categoria
    return <Stack>
        {categoria !== 'AGENTI RMA' ?
            <Stack>
                {mezzo ?
                    <Typography fontWeight={'bold'} textAlign={'center'} color={'primary'}
                                variant={'h6'}>
                        E' previsto un viaggio con
                        <br/>
                        <Trans i18nKey={`fields.${mezzo.value}`}/>
                    </Typography> : null}
            </Stack>
            : mezzo ?
                mezzo.value !== 'MEZZI PROPRI' ?
                    <Stack>
                        <Typography fontWeight={'bold'} textAlign={'center'} color={'primary'}
                                    variant={'h6'}>
                            E' previsto un viaggio con
                        </Typography>
                        {<Typography fontWeight={'bold'} textAlign={'center'} color={'primary'}
                                     variant={'h6'}>
                            {
                                userData['rma-mezzi-propri'] === 'si'
                                    ? 'MEZZI PROPRI' : t(`fields.${mezzo.value}`)
                            }
                        </Typography>}
                    </Stack>
                    : <Typography fontWeight={'bold'} textAlign={'center'} color={'primary'}
                                  variant={'h6'}>
                        E' previsto un viaggio con
                        <br/>
                        <Trans i18nKey={`fields.${mezzo.value}`}/>
                    </Typography> : null}
        <Typography gutterBottom textAlign={'center'} variant={'h6'}>{dataPartenza} - {dataRitorno}</Typography>
        <br/>
        {userData.mezzoTrasporto !== "MEZZI PROPRI" &&
            <>
                {
                    userData.mezzoTrasporto === 'VOLO' && userData?.mezzoTrasportoDiGruppo === true &&
                    <Typography gutterBottom textAlign={'center'} variant={'body1'} fontWeight={'bold'}>
                        Dal giorno 6 giugno troverai allegato su questa pagina il tuo biglietto aereo.
                    </Typography>
                }
                <Typography gutterBottom textAlign={'center'} variant={'body1'} fontWeight={'bold'}>
                    Caricheremo su questa pagina personale {userData.mezzoTrasporto === 'VOLO' && userData?.mezzoTrasportoDiGruppo === true  ? " anche " : " "}altri aggiornamenti in merito al tuo viaggio e ai
                    trasferimenti,
                    <br/>ti chiediamo di controllare prima della tua partenza tutte le informazioni utili presenti.
                </Typography>
                <br/>
            </>
        }
    </Stack>
}


export default RecapConvo