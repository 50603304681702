import React, {useMemo} from "react";
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Dialog,
    DialogContent,
    Fade,
    Grid,
    Stack,
    Typography
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import {Trans, useTranslation} from "react-i18next";
import {API_URL} from "../../config";
import WithBackground from "../WithBackground";
import {Formik} from "formik";
import {createValidationSchema} from "../../createValidationScheme";
import useAxios from "axios-hooks";
import {useDataStore} from "../../state/state";
import {useParams} from "react-router-dom";
import {AgencyFormContent, Title} from "./AgencyFormContent";
import {formMapAgentiItaliana} from "../../assets/data/form-reale-agenti-italiana";
import {cloneDeep, every, some} from "lodash";
import {profileMapAgenziaItaliana} from "../../assets/data/profile-agenzia-italiana";
import {ProfileAgencyFormField} from "./ProfileAgencyFormField";
import {profileMapAgentiItaliana} from "../../assets/data/profile-agenti-italiana";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AgencyProfile({agency}) {
    const readOnly = true
    const {id} = useParams()
    const [{data, loading, error}, refetch] = useAxios(`${API_URL}agency/${id}/participants`)

    const shouldRenderField = (field, agent) => {
        const resPredicates = field.conditions?.type === 'or' ?
            some(field.conditions?.predicates, function (o) {
                return !!agent ? agent [o.id] === o.value : agency[o.id] === o.value
            }) : field.conditions?.type === 'and' ?
                every(field.conditions?.predicates, function (o) {
                    return !!agent ? agent [o.id] === o.value : agency[o.id] === o.value
                }) : null

        return (!field.conditions || resPredicates)
    };

    const agents = useMemo(() => {
        if (!data)
            return null

        return data
    }, [data])

    return (
        <Stack>
            {
                profileMapAgenziaItaliana?.map((section, index) => {
                    return <Box mt={2} key={index} sx={{py: 1}}>
                        <Title title={section.title}
                               subtitle={(!readOnly && section.subtitle) ? section.subtitle : null}
                               numeroInviti={(!readOnly && section.numeroInviti) ?
                                   agency.numeroInviti : null}
                        />
                        <Grid container spacing={2}>
                            {section.fields.map((field, index) => (
                                shouldRenderField(field) && (
                                    <ProfileAgencyFormField
                                        agency={agency}
                                        key={index}
                                        name={field.name}
                                        id={field.id}
                                        label={field.label}
                                        type={field.type}
                                        values={field.values ?? []}
                                        cols={field.cols}
                                        precompiled={readOnly || field.precompiled}
                                        longText={field.longText}
                                        disablePast={field.disablePast}
                                        disableFuture={field.disableFuture}
                                        row={field.row}
                                    />
                                )
                            ))}
                        </Grid>
                    </Box>
                })
            }
            {loading && <CircularProgress/>}
            {
                <Stack>
                    {
                        agents?.map((agent, i) => {
                            return <>
                                {
                                    profileMapAgentiItaliana?.map((section, index) => {
                                        return shouldRenderField(section) && <Box mt={2} key={index} sx={{py: 1}}>
                                            <Title title={`${section.title} - Agente ${i + 1}`}
                                                   subtitle={(!readOnly && section.subtitle) ? section.subtitle : null}
                                            />
                                            <Grid container spacing={2}>
                                                {section.fields.map((field, index) => (
                                                    shouldRenderField(field, agent) && (
                                                        <ProfileAgencyFormField
                                                            agency={agent}
                                                            key={index}
                                                            name={field.name}
                                                            id={field.id}
                                                            label={field.label}
                                                            type={field.type}
                                                            values={field.values ?? []}
                                                            cols={field.cols}
                                                            precompiled={readOnly || field.precompiled}
                                                            longText={field.longText}
                                                            disablePast={field.disablePast}
                                                            disableFuture={field.disableFuture}
                                                            row={field.row}
                                                        />
                                                    )
                                                ))}
                                            </Grid>
                                        </Box>
                                    })
                                }
                                <Box mt={6}/>
                            </>
                        })
                    }
                </Stack>
            }
        </Stack>
    );
}

const AgencyRegistrationForm = () => {
    const {id} = useParams()
    const {t} = useTranslation()
    const {agency} = useDataStore()

    const partecipazione = agency?.partecipazione

    const [{_data, loading, _error}, executePost] = useAxios(
        {
            url: `${API_URL}agency/register/${id}`, method: 'POST'
        }, {manual: true}
    )

    const [success, setSuccess] = React.useState(false)
    const [error, setError] = React.useState(null)
    const [errorValidation, setErrorValidation] = React.useState(false)

    const initialValues = () => {
        let d = {
            richiestaDocumenti: agency.richiestaDocumenti || 'no',
            categoria: agency.categoria,
            lang: agency.lang,
            numeroInviti: agency.numeroInviti?.toString() || '',
            codiceAgenzia: agency.codiceAgenzia,
        }
        formMapAgentiItaliana.map(s => {
            return s.fields.filter(f => f.type !== 'note').forEach(f => {
                    let key = f.id
                    if (f.type === 'date' || f.type === 'datetime' || f.type === 'time') {
                        d[key] = agency?.[key] ?? null
                    } else if (f.type === 'checkbox') {
                        d[key] = agency?.[key] ?? false
                    } else {
                        d[key] = agency?.[key] ?? ''
                    }
                }
            )
        })
        return d
        // TODO CHANGE mockData
        /*return {
            ...d,
            "travelInfoCorrect": "si",
            "travelInfoCorrectCorrection": "",
            "nome": "MATILDE ITA",
            "cognome": "UGOLINI ITA",
            "indirizzo": "54 Via Amedeo Peyron",
            "cap": 10143,
            "provincia": "Piemonte",
            "citta": "Torino",
            "paese": "asd",
            "luogoNascita": "Rho",
            "dataNascita": "1992-07-18T22:00:00.000Z",
            "addressCorrect": "si",
            "addressCorrection": "",
            "prefisso": "123",
            "cellulare": 123,
            "docTipologia": "cartaDIdentita",
            "docNumero": "123",
            "docDataRilascio": "1992-07-18T22:00:00.000Z",
            "docRilasciatoDa": "asd",
            "docDataScadenza": "2939-07-18T22:00:00.000Z",
            "vegetariano": "si",
            "vegano": "si",
            "allergie": "",
            "intolleranze": "",
            "altreEsigenzeAlimentari": "",
            "altreEsigenze": ""
        }*/
    }

    const _initialValues = useMemo(() => {
        if (agency)
            return initialValues()
        else return {}
    }, [agency])

    const yupSchema = () => {
        let fields = []
        formMapAgentiItaliana.map(s => {
            return s.fields.filter(f => f.type !== 'note').forEach(f => {
                fields.push(f)
            })
        })
        let schema = createValidationSchema(fields)
        return schema
    }

    const validateSchema = yupSchema();

    const sendData = async (values) => {
        let payload = cloneDeep(values)
        const agente2 = {}
        Object.entries(values).forEach(([field, val]) => {
            if (field.endsWith('-2')) {
                agente2[field.replace('-2', '')] = val
                delete payload[field]
            }
        })

        if (!partecipazione)
            executePost({
                data: {...payload, agente2: values.agente2 === 'si' ? agente2 : null, partecipazione: 'si'}
            }).then(r => {
                setSuccess(true)
                setError(null)
            }).catch(e => {
                console.log("register > e:")
                setError('error')
            })
    }

    function handleValidationErrors(formik) {
        if (Object.keys(formik.errors).length > 0) {
            setErrorValidation(true)
        }
    }

    function handleCloseValidationErrors() {
        setErrorValidation(false)
    }

    return (
        <WithBackground bg={'/assets/images/bkg-reg-form.png'}>
            <Fade in>
                <Container maxWidth={'md'}>
                    <img src={'/assets/images/banner-1.png'} width={'100%'} alt={'header'}/>
                    {success ?
                        <Box mt={2}>
                            <Typography textAlign={'center'} variant={'h5'} fontWeight={'bold'}>
                                {t('success.message')}
                            </Typography>
                        </Box>
                        :
                        <Box sx={{pt: 0, pb: 8}}>
                            {partecipazione ?
                                <>
                                    <Typography sx={{my: 1}} textAlign={'center'} variant={'h5'} fontWeight={'bold'}>
                                        {t('success.message')}
                                    </Typography>
                                    <AgencyProfile agency={agency}/>
                                </>
                                :
                                <Formik
                                    validationSchema={validateSchema}
                                    initialValues={{
                                        ..._initialValues
                                    }}
                                    enableReinitialize
                                    validateOnChange={true}
                                    validateOnBlur={false}
                                    validateOnMount={true}
                                    onSubmit={values => {
                                        sendData(values)
                                    }}
                                >
                                    {(formik) =>
                                        <form onSubmit={formik.handleSubmit}>
                                            <AgencyFormContent readOnly={!!partecipazione} formik={formik}/>
                                            <Dialog open={errorValidation} onClose={handleCloseValidationErrors}>
                                                <DialogContent>
                                                    <Typography gutterBottom>
                                                        {t('shared.error-validation-label')}
                                                    </Typography>
                                                    {
                                                        Object.keys(formik.errors).map((field) => (
                                                            <Typography>
                                                                <Trans i18nKey={`fields.${field}`}/>
                                                            </Typography>
                                                        ))
                                                    }
                                                </DialogContent>
                                            </Dialog>
                                            <Box sx={{textAlign: 'center', pt: 2}}>
                                                {loading ? <CircularProgress/> :
                                                    !partecipazione ?
                                                        <Stack alignItems={'center'} spacing={1}>
                                                            <Button variant={'contained'}
                                                                    sx={{width: 'min(20rem, 100%)', fontSize: '1.4rem'}}
                                                                    type={'submit'}
                                                                    onClick={() => handleValidationErrors(formik)}
                                                            >
                                                                Iscrivi agente/i
                                                            </Button>
                                                            <Typography>{t('shared.checkFields')}</Typography>
                                                        </Stack> : null
                                                }
                                                {error ?
                                                    <Typography variant={'h5'} textAlign={'center'}>
                                                        {t(`shared.error`)}
                                                    </Typography> : null}
                                            </Box>
                                        </form>
                                    }
                                </Formik>
                            }
                        </Box>
                    }
                </Container>
            </Fade>
        </WithBackground>
    )
}

export default AgencyRegistrationForm