import WithBackground from "./WithBackground";
import {Trans, useTranslation} from "react-i18next";
import {Box, Stack, Typography, useMediaQuery} from "@mui/material";

export const SuccessPage = () => {
    const {t} = useTranslation()
    const matches = useMediaQuery('(min-width:600px)')


    return (
        <WithBackground bg={matches ? '/assets/images/bkg-lightning.png' : '/assets/images/bkg-lightning-mobile.png'}>
            <Box margin={'auto'}>
                <Stack alignItems={'center'} px={4}>
                    <img src={'/assets/images/titolo-conv.png'}
                         width={'70%'}/>
                    <Typography textAlign={'center'} variant={'h5'}>
                        <Trans i18nKey={'playwithus.success'} components={{1: <Typography variant={'h4'} fontWeight={'bold'}/>}}/>
                    </Typography>
                </Stack>
            </Box>
        </WithBackground>
    );
}
