import {Box, CircularProgress, Container, Divider, Stack, Typography} from "@mui/material";
import useAxios from "axios-hooks";
import {API_URL} from "../config";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import WithBackground from "../components/WithBackground";
import Treno from "../components/Logistic/Treno";
import Pernotto from "../components/Logistic/Pernotto";
import Transfer from "../components/Logistic/Transfer";
import Volo from "../components/Logistic/Volo";
import RecapConvo from "../components/Logistic/RecapConvo";
import {winstonTheme} from "../theme/winstonTheme";
import Viaggio from "../components/Logistic/Viaggio";
import {FileMenu} from "../components/Logistic/Biglietti";

function Agenda({qrCodeUrl}) {
    return (
        <Stack spacing={1}>
            <Typography textAlign={'left'} variant={'h6'} fontWeight={'bold'}
                        color={'primary'}>
                QR CODE CHECK-IN
            </Typography>
            <Typography gutterBottom>Di seguito trovi il QR Code da presentare al momento
                dell'accredito</Typography>
            <br/>

            <Stack alignItems={'center'} spacing={1}>
                <img src={qrCodeUrl} width={150} style={{margin: 'auto', borderRadius: '0.75rem'}}/>
                <FileMenu biglietto={qrCodeUrl} label={"QR code"} fileName={'QR-code'} qr={true}/>
            </Stack>

            <Stack mt={1}>
                <Typography textAlign={'left'} variant={'h6'} fontWeight={'bold'}
                            color={'primary'}>
                    AGENDA
                </Typography>
                <Typography>L'agenda del 12 giugno sarà questa:</Typography>
                <br/>
                <Typography><strong>12:00</strong> - CHECK IN CON QR CODE PERSONALE E INGRESSO OSPITI</Typography>
                <Typography><strong>12:00</strong> - INIZIO PRANZO A BUFFET</Typography>
                <Typography><strong>13:45</strong> - INIZIO CONVENTION</Typography>
                <Typography><strong>17:30</strong> - FINE EVENTO ED USCITA OSPITI</Typography>

                <br/>
                <Typography><strong>Dress Code:</strong> Business Casual</Typography>
                <br/>

                <Divider sx={{my: 2}}/>

                <Stack direction={'row'} mt={1} justifyContent={'center'} spacing={1}>
                    <b>Se vuoi puoi importare sul tuo calendario l'evento:</b>
                    <br/>
                    <a style={{color: winstonTheme.palette.primary.main}}
                       href="https://calndr.link/e/Nl3tijXno0?s=apple">
                        <strong>Apple</strong>
                    </a><strong> • </strong>
                    <a style={{color: winstonTheme.palette.primary.main}}
                       href="https://calndr.link/e/Nl3tijXno0?s=google">
                        <strong>Google</strong>
                    </a>
                    <strong> • </strong>
                    <a style={{color: winstonTheme.palette.primary.main}}
                       href="https://calndr.link/e/Nl3tijXno0?s=outlook">
                        <strong>Outlook</strong>
                    </a>
                </Stack>
            </Stack>
        </Stack>
    );
}

const Logistic = () => {
    const {t} = useTranslation()
    const {id} = useParams()
    const [{data, loading, error}, refetch] = useAxios(`${API_URL}logistic/${id}`)
    const [errorCategoria, setErrorCategoria] = useState(false)

    useEffect(() => {
        if (data) {
            if (data.categoria !== 'DIPENDENTI' && data.categoria !== 'AGENTI RMA' && data.categoria !== 'AGENTI ITA') {
                //console.log("data.categoria:", data.categoria)
                setErrorCategoria(true)
            }
        }

    }, [data])

    return (
        <Stack>
            {
                loading ?
                    <Box sx={{textAlign: 'center'}}>
                        <CircularProgress/>
                    </Box>
                    : (error || errorCategoria) ?
                        <Typography textAlign={'center'}>
                            {t('shared.user-not-available')}
                        </Typography>
                        : data ?
                            <WithBackground bg={'/assets/images/bkg-reg-form.png'}>
                                {data.partecipazione === "si" ?
                                    data.invioConvocazioneManuale === "si" ?
                                        <Stack>
                                            La segreteria si occuperà di contattarti e fornirti tutti i dettagli necessari
                                            per il tuo viaggio
                                        </Stack>
                                        : <Container maxWidth={'md'}
                                                     sx={{pb: 3}} /*component={Paper} sx={{background: winstonTheme.palette.secondary.main}}*/>
                                            <img src={'/assets/images/banner-1.png'} width={'100%'} alt={'header'}/>
                                            <Typography mt={2} variant={"h5"} fontWeight={'bold'} textAlign={'center'}
                                                        color={'primary'}>
                                                CONVOCAZIONI
                                            </Typography>
                                            {data.categoria === 'AGENTI ITA' &&
                                                <Typography textAlign={'center'} fontWeight={'bold'}>
                                                    {data.aziendaAgenzia}
                                                    <br/>
                                                    Codice Agenzia: {data.codiceAgenzia}
                                                </Typography>}
                                            <Typography textAlign={'center'}>
                                                {data.nome.toUpperCase()} {data.cognome.toUpperCase()}
                                            </Typography>
                                            <br/>

                                            <RecapConvo userData={data}/>

                                            <ConvocazioniContainer userData={data}/>
                                            {
                                                !!data.hotel && <>
                                                    <Divider sx={{my: 2}}/>
                                                    <Pernotto userData={data}/>
                                                </>
                                            }
                                            <Divider sx={{my: 2}}/>
                                            <Typography>
                                                <strong>N.B.</strong>
                                                <br/>
                                                Nel caso tu avessi indicato segnalazioni alimentari, ti comunichiamo che
                                                troverai tutte le indicazioni utili sui menu del buffet.
                                            </Typography>
                                            <Divider sx={{my: 2}}/>
                                            <Agenda qrCodeUrl={data.qrCodeUrl}/>
                                        </Container>
                                    : <Typography>
                                        La sua iscrizione non risulta valida
                                    </Typography>}
                            </WithBackground>
                            : null
            }
        </Stack>
    )
}

export default Logistic

function MezziPropri({userData}) {
    return (
        <Stack>
            <Typography>
                Raggiungerai Inalpi Arena autonomamente, per questo <b>ti suggeriamo fortemente di usare i mezzi pubblici</b> attraverso i quali è comodamente collegato con il centro città.
                <br/>
                Inalpi Arena si trova in Corso Sebastopoli 123, in prossimità dello stadio: <a style={{color: winstonTheme.palette.primary.main, fontWeight: 'bold'}} href={`https://maps.app.goo.gl/6jCkGVrMhhYjYvim6`} target={'_blank'}>QUI</a>, su Google Maps.
                <br/>
                <br/>
                <b>In autobus e tram:</b> la linea del tram 4 arriva direttamente da Piazza Castello e da Stazione di Porta Nuova (treni e/o metropolitana);
                in alternativa il tram linea 10 collega con la stazione metro Vinzaglio e con la stazione ferroviaria di Porta Susa, ma anche i bus linee 14 (passa da stazione ferroviaria Lingotto), 17 e 63 si fermano in corrispondenza dello stadio.
                <ul>
                    <li>sia per il tram 4 che per il tram 10, la fermata è “Sebastopoli”</li>
                </ul>
                <br/>
                <b>In treno</b>, le tre stazioni ferroviarie cittadine – Porta Nuova, Porta Susa, Lingotto – sono tutte collegate allo stadio tramite un autobus o un tram diretto (vedi dettaglio autobus e tram, sopra).
                <br/>
                <br/>
                Se proprio non puoi usare i mezzi pubblici e devi spostarti <b>in auto</b>, cerca di non viaggiare da solo ma di offrire passaggi ad altri due o tre colleghi che possono fare il trasferimento con te. Ti segnaliamo inoltre che non sono previsti posti auto riservati per l’evento,
                ma nei controviali di corso Galileo Ferraris e corso Giovanni Agnelli (lungo il parco) ci sono aree di parcheggio a pagamento (8 euro tutta la giornata). In alternativa, un’altra area di parcheggio a pagamento è situata in Piazza Caio Mario, e da lì poi si raggiunge l’evento tramite la linea tram 4.
            </Typography>
        </Stack>
    );
}

function Bus({userData}) {
    return <Stack>
        <Viaggio userData={userData} mezzoTrasporto={userData.mezzoTrasporto} direzione={'andata'}/>
        <Viaggio userData={userData} mezzoTrasporto={userData.mezzoTrasporto} direzione={'ritorno'}/>
    </Stack>;
}


function ConvocazioniContainer({userData}) {
    return (
        <Stack>
            {(() => {
                switch (userData.mezzoTrasporto) {
                    case 'MEZZI PROPRI':
                        return <MezziPropri userData={userData}/>
                    case 'TRENO':
                        return <>
                            <Treno userData={userData}/>
                            <Transfer userData={userData}/>
                        </>
                    case 'VOLO':
                        return <>
                            <Volo userData={userData}/>
                            <Transfer userData={userData}/>
                        </>
                    case 'BUS':
                        return <>
                            <Bus userData={userData}/>
                            <Transfer userData={userData}/>
                        </>
                }
            })()}
        </Stack>
    );
}
