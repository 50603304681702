import {createTheme, responsiveFontSizes} from "@mui/material/styles";

export const winstonTheme = responsiveFontSizes(createTheme({
    typography: {
        fontFamily: 'Titillium'
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    "& .MuiFilledInput-root": {
                        background: "rgba(255,255,255,0.9)",
                        color: 'rgba(0,43,101,1)',
                        "&:hover": {
                            background: "rgba(255,255,255,0.75)",
                        },
                        "&.Mui-focused": {
                            background: "rgba(255,255,255,0.75)",
                        },
                        "&.Mui-disabled": {
                            background: "rgba(255,255,255,0.5)",
                        },
                        "&.Mui-error": {
                            borderStyle: "solid",
                            borderWidth: "2px",
                            borderColor: '#e30000'
                        },
                    },
                    "& .Mui-disabled": {
                        "& .MuiInputBase-input": {
                            WebkitTextFillColor: "rgba(0,43,101,0.85)",
                            color: 'rgba(0,43,101,0.85)'
                        },
                    },
                }
            }
        },
        MuiFormLabel: {
            variants: [
                {
                    props: { variant:'radio' },
                    style: {
                        color: 'inherit'
                    }
                }
            ],
            styleOverrides: {
                root: {
                    color: 'white'
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    color: 'black',
                    backgroundColor: "rgba(255,255,255,0.9)",
                    "&:hover": {
                        backgroundColor: "rgba(255,255,255,0.75)",
                    },
                    "&.Mui-focused": {
                        backgroundColor: "rgba(255,255,255,0.75)",
                    },
                    "&.Mui-error": {
                        borderStyle: "solid",
                        borderWidth: "2px",
                        borderColor: '#e30000'
                    },
                },
                filled: {
                    "& .MuiSelect-select": {
                        backgroundColor: "rgba(255,255,255,0.9)",
                        "&:hover": {
                            backgroundColor: "rgba(255,255,255,0.75)",
                        },
                        "&.Mui-focused": {
                            backgroundColor: "rgba(255,255,255,0.75)",
                        },
                    }
                },
                icon: {
                    color: '#01397e',
                },
            }
        }
    },
    palette: {
        mode: 'dark',
        /*background: {
            default: '#080e19'
        },*/
        text: {
          primary: '#FFFFFF'
        },
        primary: {
            main: '#fdc002'
        },
        secondary: {
            main: '#024da1'
        },
        divider:'#fdc002',
        error: {
            main: '#fca574'
        }
    }
}));