import {Backdrop, Box, Button, CircularProgress, Container, Fade, Stack, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import WithBackground from "./WithBackground";
import {useTranslation} from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import {some} from "lodash";
import {winstonTheme} from "../theme/winstonTheme";
import useAxios from "axios-hooks";
import {API_URL} from "../config";
import {useNavigate, useParams} from "react-router-dom";

const Soundtrack = () => {
    const {id} = useParams()
    const {t, i18n} = useTranslation()
    const navigate = useNavigate()

    const [{data, loading, error}, refetch] = useAxios(`${API_URL}soundtrack/profile/${id}`)

    useEffect(() => {
        if(data) {
            if (!!data.lang) {
                i18n.changeLanguage(data?.lang || 'it')
            }

            if(!!data.partecipazioneSoundtrack)
                navigate('/soundtrack/success')
        }
    },[data])

    const [{data: dataSaving, loading: saving, error: errorSaving}, executePost] = useAxios(
        {
            url: `${API_URL}soundtrack/register/${id}`, method: 'POST'
        }, {manual: true}
    )

    const [state, setState] = useState({
        soundtrackSentence: '',
        soundtrackGenre: null, //Pop, Rock, Jazz, Clásica, Electrónica
        soundtrackInstrument: null//Guitarra, Basso, Tastiera, Batería, Violín
    })

    const handleChange = (event) => {
        const fieldId = event.target.id || event.target.name

        if(fieldId !== 'soundtrackSentence' || event.target.value.length <= 140)
            setState({...state, [fieldId]: event.target.value});
        setState({...state, [fieldId]: event.target.value});
    };

    function handleSubmit() {
        executePost({
            data: {...state, partecipazioneSoundtrack: 'si'}
        }).then(r => {
            navigate('/soundtrack/success')
        }).catch(e => {
            console.log("register > e:")
        })
    }

    return (
        <WithBackground bg={'/assets/images/bkg-reg-form.png'}>
            {loading && <CircularProgress/>}
            {error && <Stack alignItems={'center'}>
                <Typography textAlign={'center'}>{t('shared.user-not-available')}</Typography>
            </Stack>}
            <Fade in={!loading && data && !error} mountOnEnter unmountOnExit>
                <Container maxWidth={'md'}>
                    <img src={'/assets/images/banner-3.png'} width={'100%'} alt={'header'}/>
                    {<Stack mt={2} pb={4} alignItems={'center'} spacing={2}>
                            <Stack alignItems={'center'} maxWidth={'400px'}>
                                <Typography gutterBottom textAlign={'center'} variant={'h5'} fontWeight={'bold'}>
                                    {t('soundtrack.title')}
                                </Typography>
                                <Typography gutterBottom textAlign={'center'} variant={'h6'} maxWidth={'250px'}>
                                    {t('soundtrack.subtitle')}
                                </Typography>
                                <Typography gutterBottom textAlign={'center'} variant={'h5'} fontWeight={'bold'}>
                                    {t('soundtrack.cta')}
                                </Typography>
                            </Stack>
                            <Box my={2} sx={{width:'100%', height:'0.15rem', background: winstonTheme.palette.primary.main}}/>

                            <Box width={'100%'}>
                                <Typography variant={'h6'}>{t(`soundtrack.fields.soundtrackSentence`)}</Typography>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="soundtrackSentence"
                                    type="text"
                                    multiline
                                    minRows={3}
                                    maxRows={4}
                                    placeholder={t('shared.max-char')}
                                    fullWidth
                                    variant="filled"
                                    required
                                    value={state.soundtrackSentence}
                                    onChange={handleChange}
                                />
                            </Box>

                            <Box display={'flex'} width={'100%'}>
                                <Typography sx={{flex: 1}} variant={'h6'}>{t(`soundtrack.fields.soundtrackGenre`)}</Typography>
                                <TextField
                                    sx={{flex: 1}}
                                    select
                                    margin="dense"
                                    name="soundtrackGenre"
                                    type="text"
                                    fullWidth
                                    variant="filled"
                                    required
                                    value={state.soundtrackGenre}
                                    onChange={handleChange}
                                >
                                    {["pop", "rock", "jazz", "classica", "elettronica"].map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {t(`soundtrack.select.${option}`)}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>

                            <Box display={'flex'} width={'100%'}>
                                <Typography sx={{flex: 1}} variant={'h6'}>{t(`soundtrack.fields.soundtrackInstrument`)}</Typography>
                                <TextField
                                    sx={{flex: 1}}
                                    select
                                    margin="dense"
                                    name="soundtrackInstrument"
                                    type="text"
                                    fullWidth
                                    variant="filled"
                                    required
                                    value={state.soundtrackInstrument}
                                    onChange={handleChange}
                                >
                                    {["chitarra", "basso", "tastiere", "batteria", "violino"].map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {t(`soundtrack.select.${option}`)}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>

                            <Box py={3} sx={{textAlign: 'center',}}>
                                <Button color={'primary'} variant={'contained'} type={'submit'}
                                        sx={{
                                            minWidth: '190px',
                                            fontSize: '1.5rem',
                                            marginTop: "0 !important"
                                        }}
                                        disabled={(some(Object.values(state), function (o) {
                                            return !o
                                        }))}
                                        onClick={handleSubmit}
                                >
                                    {t('shared.confirm')}
                                </Button>
                            </Box>
                        </Stack>
                    }
                </Container>
            </Fade>
        </WithBackground>
    )
}

export default Soundtrack