import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Container,
    FormControlLabel,
    FormGroup,
    Radio,
    RadioGroup,
    Stack,
    Typography,
    useMediaQuery
} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import FormControl from "@mui/material/FormControl";
import React, {useEffect, useState} from "react";
import {useDataStore} from "../state/state";
import WithBackground from "./WithBackground";
import {useNavigate, useParams} from "react-router-dom";
import RegistrationForm from "./RegistrationForm";
import useAxios from "axios-hooks";
import {API_URL} from "../config";
import {winstonTheme} from "../theme/winstonTheme";
import ClosedSubscriptions from "./ClosedSubscriptions";

const FormContainer = () => {
    const {id} = useParams()
    const matches = useMediaQuery('(min-width:600px)')

    const {initialize, update, data} = useDataStore()
    const navigate = useNavigate()
    const {t, i18n} = useTranslation()

    const [{_data, loading, _error}, executePost] = useAxios(
        {
            url: `${API_URL}register/${id}`, method: 'POST'
        }, {manual: true}
    )

    const [success, setSuccess] = React.useState(false)
    const [error, setError] = React.useState(null)

    const [state, setState] = useState({privacy: false, partecipazione: null})
    const handleChange = (event, checked) => {
        const value = checked !== undefined ? checked : event.target.value
        //update(event.target.id || event.target.name, value)

        setState({...state, [event.target.id || event.target.name]: value});
    };

    /** TODO: chhange */
    const [nextStep, setNextStep] = useState(false)

    const sendData = async (e) => {
        e.preventDefault()

        if (state.partecipazione === 'no') {
            executePost({
                data: {partecipazione: 'no', privacy: state.privacy}
            }).then(r => {
                setSuccess(true)
                setError(null)
            }).catch(e => {
                console.log("register no > e:", e)
                setError('error')
            })
        } else {
            setNextStep(true)
        }

    }

    useEffect(() => {
        if (data && data.partecipazione === 'si')
            setNextStep(true)
    }, [data])

    return (
        <>
            {
                data ?
                    !data.partecipazione ?
                        <ClosedSubscriptions/>
                    : nextStep ?
                        <RegistrationForm/>
                        :
                        <WithBackground
                            bg={matches ? '/assets/images/bkg-arrows.png' : '/assets/images/bkg-arrows-mobile.png'}>
                            <Stack margin={'auto'} alignItems={'center'} justifyContent={'center'}>
                                <Stack mt={{xs: 'auto', sm: 'auto'}} alignItems={'center'}>
                                    <img
                                        src={matches ? '/assets/images/titolo-conv.png' : '/assets/images/titolo-conv-mobile.png'}
                                        width={'60%'}/>
                                </Stack>
                                {
                                    loading &&
                                    <Box sx={{textAlign: 'center'}}>
                                        <CircularProgress/>
                                    </Box>
                                }
                                {
                                    (success || data.partecipazione === 'no') ?
                                        <Typography variant={'h3'}>
                                            {t('success.message-no-participation')}
                                        </Typography>
                                        : error ?
                                            <Typography variant={'h5'} textAlign={'center'}>
                                                {t(`shared.error`)}
                                            </Typography>
                                            : <Box>
                                                <form onSubmit={sendData}>
                                                    <Container maxWidth={'md'}>
                                                        <Stack mt={1} alignItems={'center'}>
                                                            <Typography variant={'h5'} fontWeight={'bold'}
                                                                        textAlign={'center'}
                                                                        color={'primary'}>
                                                                <Trans i18nKey={'pre-registration.title'}/>
                                                            </Typography>
                                                            <Typography variant={'body1'} fontWeight={'bold'}
                                                                        textAlign={'center'}
                                                                        color={'primary'}>
                                                                <Trans i18nKey={'pre-registration.sub-title'}/>
                                                            </Typography>
                                                            <Stack direction={'row'} alignItems={'center'} spacing={2}>
                                                                <Typography variant={matches ? 'h4' : 'h5'}
                                                                            fontWeight={'bold'}
                                                                            textAlign={'center'}>
                                                                    {t('pre-registration.label')}
                                                                </Typography>
                                                                <FormControl>
                                                                    <RadioGroup
                                                                        row
                                                                        value={state.partecipazione}
                                                                        onChange={handleChange}
                                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                                        id="partecipazione"
                                                                        name="partecipazione"
                                                                    >
                                                                        <FormControlLabel value="si" control={<Radio/>}
                                                                                          label={t('shared.yes')}/>
                                                                        <FormControlLabel value="no" control={<Radio/>}
                                                                                          label={t('shared.no')}/>
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            </Stack>

                                                            <Stack mt={1} alignItems={'center'} sx={{textAlign: 'center'}}
                                                                   maxWidth={'320px'}>
                                                                <Typography gutterBottom fontWeight={'bold'}>
                                                                    {t('pre-registration.privacy.title')}
                                                                </Typography>
                                                                <Typography variant={'body2'}>
                                                                    {t('pre-registration.privacy.body')}
                                                                </Typography>
                                                                <a style={{color: winstonTheme.palette.primary.main, fontWeight: 'bold'}}
                                                                   href={`/privacy-policy-${i18n.language}.pdf`}
                                                                   target={'_blank'}
                                                                >
                                                                    Privacy
                                                                </a>
                                                                <FormControl sx={{mt: 1}}>
                                                                    <FormGroup row sx={{alignItems: 'center'}}>
                                                                        <FormControlLabel
                                                                            labelPlacement={'start'}
                                                                            control={<Checkbox
                                                                                id={'privacy'}
                                                                                name={'privacy'}
                                                                                checked={state.privacy}
                                                                                onChange={handleChange}
                                                                            />} label={t('shared.accept')}/>
                                                                    </FormGroup>
                                                                </FormControl>
                                                            </Stack>

                                                            <Box pb={3} mt={1} sx={{textAlign: 'center',}}>
                                                                <Button color={'secondary'} variant={'contained'}
                                                                        type={'submit'}
                                                                        sx={{minWidth: '170px', fontSize: '1.5rem'}}
                                                                        disabled={!state.privacy || !state.partecipazione}
                                                                >
                                                                    {t('shared.next')}
                                                                </Button>
                                                            </Box>
                                                        </Stack>
                                                    </Container>
                                                </form>
                                            </Box>
                                }
                            </Stack>
                        </WithBackground>
                    : null
            }
        </>
    )
}

export default FormContainer