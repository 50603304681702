export const profileMapAgenziaItaliana = [
    {
        id: "agenziaInfo",
        title: "ECCO LA TUA AREA PERSONALE.",
        subtitle: "Compila o completa i tuoi dati entro le ore 15:00 (CEST) del 2/5/2024.",
        numeroInviti: "L'agenzia dispone di ",
        readOnly: true,
        fields: [
            {
                id: 'aziendaAgenzia',
                name: 'aziendaAgenzia',
                label: 'Nome Agenzia',
                type: 'text',
                precompiled: true,
                params: {
                    required: "Campo obbligatorio"
                },
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'ragioneSociale',
                name: 'ragioneSociale',
                label: 'Ragione Sociale',
                type: 'text',
                precompiled: true,
                params: {
                    required: "Campo obbligatorio"
                },
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'codiceAgenzia',
                name: 'codiceAgenzia',
                label: 'Codice Agenzia',
                type: 'email',
                validations: ['string', "required"],
                required: true,
                precompiled: true,
                params: {
                    required: "Required Field"
                },
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'email',
                name: 'email',
                label: 'email',
                type: 'email',
                validations: ['string', "required"],
                required: true,
                precompiled: true,
                params: {
                    required: "Required Field"
                },
                cols: {
                    xs: 12,
                    sm: 6
                }
            }
        ]
    },
    {
        id: "travelInfo",
        title: "VIAGGI E TRASFERIMENTI",
        subtitle: "Il viaggio è uguale per tutti gli agenti iscritti",
        readOnly: true,
        fields: [
            {
                id: 'cittaPartenzaArrivo',
                name: 'cittaPartenzaArrivo',
                label: 'Città di partenza e rientro',
                type: 'text',
                precompiled: true,
                params: {
                    required: "Required Field"
                },
                cols: {
                    xs: 12,
                    sm: 12
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "lang",
                            value: "it"
                        }
                    ]
                }
            },
            {
                id: 'dataPartenza',
                name: 'dataPartenza',
                label: 'Andata',
                type: 'date',
                precompiled: true,
                params: {
                    required: "Required Field"
                },
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'dataRitorno',
                name: 'dataRitorno',
                label: 'Ritorno',
                type: 'date',
                precompiled: true,
                params: {
                    required: "Required Field"
                },
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'mezzoTrasporto',
                name: 'mezzoTrasporto',
                label: 'Mezzo di trasporto',
                type: 'custom',
                precompiled: false,
                cols: {
                    xs:12,
                    md: 12
                }
            },
            /*
            {
                id: 'mezzoTrasporto',
                name: 'mezzoTrasporto',
                label: 'mezzoTrasporto',
                type: 'text',
                precompiled: true,
                cols: {
                    xs: 12,
                    sm: 12
                }
            },
            {
                id: 'note-mezzoTrasporto-proprio',
                name: 'note-mezzoTrasporto-proprio',
                label: 'note-mezzoTrasporto-proprio',
                type: 'note',
                precompiled: true,
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "mezzoTrasporto",
                            value: "mezzoProprio"
                        }
                    ]
                },
                cols: {
                    xs:12,
                    md: 12
                }
            },
            {
                id: 'note-mezzoTrasporto-organizzato',
                name: 'note-mezzoTrasporto-organizzato',
                label: 'note-mezzoTrasporto-organizzato',
                type: 'note',
                precompiled: true,
                conditions: {
                    type: "or",
                    predicates: [
                        {
                            id: "mezzoTrasporto",
                            value: "aereo"
                        },
                        {
                            id: "mezzoTrasporto",
                            value: "bus"
                        },
                        {
                            id: "mezzoTrasporto",
                            value: "treno"
                        }
                    ]
                },
                cols: {
                    xs:12,
                    md: 12
                }
            },*/
            {
                id: 'travelInfoCorrect',
                name: 'travelInfoCorrect',
                label: 'Confermi i dettagli del viaggio? *',
                type: 'radiogroup',
                required: true,
                validations: ["string", 'required'],
                precompiled: false,
                values: [
                    {
                        value: "si",
                        label: "Si"
                    },
                    {
                        value: "no",
                        label: "No"
                    }
                ],
                params: {
                    required: "Required Field",
                }
            },
            {
                id: "note-2",
                name: "note-2",
                type: 'note',
                label: "* Se i dettagli del viaggio non sono corretti scrivici qui le tue note. Ti daremo riscontro sulla fattibilità della tua richiesta.",
                validations: ["string", 'when'],
                params: {
                    when: [
                        ['travelInfoCorrect'], {
                            is: (travelInfoCorrect) => travelInfoCorrect === "no",
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "travelInfoCorrect",
                            value: "no"
                        }
                    ]
                },
                cols: {
                    xs:12,
                    md: 12
                }
            },
            {
                id: "travelInfoCorrectCorrection",
                name: "travelInfoCorrectCorrection",
                label: "Scrivici qui i dati corretti",
                type: "text",
                longText: true,
                precompiled: false,
                validations: ["string", 'when'],
                params: {
                    when: [
                        ['travelInfoCorrect'], {
                            is: (travelInfoCorrect) => travelInfoCorrect === "no",
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "travelInfoCorrect",
                            value: "no"
                        }
                    ]
                },
                cols: {
                    xs:12,
                    md: 12
                }
            },
        ]
    },
]