import {Divider, Stack, Typography} from "@mui/material";
import React from "react";

const Title = ({viaggio}) => {
    return <>
        {viaggio === "ANDATA" && <Divider sx={{my: 2}}/>}
        <Typography variant={'h6'} fontWeight={'bold'} color={'primary'}>TRANSFER {viaggio}</Typography>
    </>
}

const Transfer = ({userData}) => {
    return (
        (!!userData.dataPartenza || !!userData.dataRitorno) &&
        <Stack>
            {(() => {
                switch (userData.dataPartenza) {
                    case "2024-06-10T22:00:00.000Z":
                    case "2024-06-11T00:00:00.000Z":
                        return userData.mezzoTrasporto === "VOLO" ?
                            (userData.andataStazioneArrivo2?.toLowerCase() === "milano linate") ?
                                <>
                                    <Title viaggio={"ANDATA"}/>
                                    <Typography>
                                        All'arrivo all'aeroporto di <b>Milano Linate</b>, troverai un assistente che ti
                                        darà il
                                        benvenuto e le indicazioni per il trasferimento organizzato per l’hotel.
                                        {/*L'assistente è contattabile in caso di necessità a <b>Cell. 335 00000000 solo nei giorni di
                                                        evento</b>*/}
                                    </Typography>
                                </>
                                :
                                <>
                                    <Title viaggio={"ANDATA"}/>
                                    <Typography>
                                        All’arrivo all’aeroporto di <b>Torino Caselle</b>, troverai un assistente che ti
                                        darà il benvenuto e le indicazioni per il trasferimento organizzato all’hotel.
                                        {/*<br/>
                                    L’assistente è contattabile in caso di necessità al numero #123456789 (il numero
                                    sarà attivo da martedì 11 giugno).*/}
                                    </Typography>
                                </>
                            : userData.mezzoTrasporto === "TRENO" ?
                                <>
                                    <Title viaggio={"ANDATA"}/>
                                    <Typography>
                                        All’arrivo alla stazione di <strong>Torino Porta Susa</strong>, all'uscita
                                        lato <b>Corso Bolzano presso gli stalli dell’Autostazione</b>,
                                        troverai un assistente,
                                            {userData.mezzoTrasportoDiGruppo === true
                                                ? <b> che puoi contattare al numero +39 345 391 1022 – disponibile solo durante la giornata di evento, </b>
                                            : <b> che puoi contattare al numero +39 345 391 1022 – disponibile solo durante le giornate dell’11 e 12 giugno, </b>
                                    }
                                        che ti darà il benvenuto e le indicazioni per il trasferimento organizzato all’hotel.
                                        {/*<br/>
                                        L’assistente è contattabile in caso di necessità al numero #123456789 (il numero
                                        sarà attivo da martedì 11 giugno).*/}
                                    </Typography>
                                </> : null
                    case "2024-06-11T22:00:00.000Z":
                    case "2024-06-12T00:00:00.000Z":
                        return userData.mezzoTrasporto === "VOLO" ?
                            (userData.andataStazioneArrivo2?.toLowerCase() === "milano linate") ?
                                <>
                                    <Title viaggio={"ANDATA"}/>
                                    <Typography>
                                        All'arrivo all'aeroporto di <b>Milano Linate</b>, troverai un assistente che ti
                                        darà il
                                        benvenuto e le indicazioni per il trasferimento organizzato verso la location dell'evento.
                                        {/*L'assistente è contattabile in caso di necessità a <b>Cell. 335 00000000 solo nei giorni di
                                                        evento</b>*/}
                                    </Typography>
                                </>
                                :
                                <>
                                    <Title viaggio={"ANDATA"}/>
                                    <Typography>
                                        All’arrivo all’aeroporto di <strong>Torino Caselle</strong>, troverai un
                                        assistente che ti darà il benvenuto e le indicazioni per il trasferimento
                                        organizzato verso la location dell’evento.
                                        {/*<br/>
                                            L’assistente è contattabile in caso di necessità al numero #123456789 (il
                                            numero sarà attivo da mercoledì 12 giugno).*/}
                                    </Typography>
                                </>
                            : userData.mezzoTrasporto === "TRENO" ?
                                <>
                                    <Title viaggio={"ANDATA"}/>
                                    <Typography>
                                        All’arrivo alla stazione di <strong>Torino Porta Susa</strong>, all'uscita
                                        lato <b>Corso Bolzano presso gli stalli dell’Autostazione</b>,
                                        troverai un assistente,
                                        {userData.mezzoTrasportoDiGruppo === true
                                            ? <b> che puoi contattare al numero +39 345 391 1022 – disponibile solo durante la giornata di evento, </b>
                                            : <b> che puoi contattare al numero +39 345 391 1022 – disponibile solo durante le giornate dell’11 e 12 giugno, </b>
                                        }
                                        che ti darà il benvenuto e le indicazioni per il
                                        trasferimento organizzato verso la location dell’evento.
                                        {/*<br/>
                                        L’assistente è contattabile in caso di necessità al numero #123456789 (il numero
                                        sarà attivo da mercoledì 12 giugno).*/}
                                    </Typography>
                                </> : null
                    default:
                        return null
                }
            })()}
            {(() => {
                switch (userData.dataRitorno) {
                    case "2024-06-11T22:00:00.000Z":
                    case "2024-06-12T00:00:00.000Z":
                        return userData.mezzoTrasporto === "VOLO" ?
                            <>
                                <br/>
                                <Title viaggio={"RITORNO"}/>
                                <Typography>
                                    A fine evento troverai un transfer organizzato verso l’aeroporto di <strong>Torino
                                    Caselle</strong>.
                                </Typography>
                            </>
                            : userData.mezzoTrasporto === "TRENO" ?
                                <>
                                    <br/>
                                    <Title viaggio={"RITORNO"}/>
                                    <Typography>
                                        A fine evento troverai un transfer organizzato verso la stazione di <strong>Torino
                                        Porta Susa</strong>.
                                    </Typography>
                                </>
                                : userData.mezzoTrasporto === "BUS" ?
                                    <>
                                        <br/>
                                        <Title viaggio={"RITORNO"}/>
                                        <Typography>
                                            Il bus ti riaccompagnerà allo stesso punto di raccolta della partenza.
                                        </Typography>
                                    </>
                                    : null
                    case "2024-06-12T22:00:00.000Z":
                    case "2024-06-13T00:00:00.000Z":
                        return (userData.id !== "MfM0XSme41f0NEfa4Kvs" && userData.id !== "lqiSRMJBjhhLiWdl7l14")
                            && <>
                                <br/>
                                <Title viaggio={"RITORNO"}/>
                                <Typography>
                                    Il giorno <strong>13/06</strong> ci sarà un transfer organizzato per il tuo rientro, lo
                                    staff in loco di fornirà tutti i dettagli.
                                </Typography>
                            </>
                    default:
                        return null
                }
            })()}
            <Typography fontWeight={'bold'}>
                Il giorno dell’evento, per il trasferimento a fine lavori, ti chiediamo di recarti nello stesso
                parcheggio in cui il bus ti ha lasciato all’andata.
            </Typography>
        </Stack>
    )
}

export default Transfer