import WithBackground from "./WithBackground";
import {Trans, useTranslation} from "react-i18next";
import {Box, Stack, Typography, useMediaQuery} from "@mui/material";

export const SoundtrackSuccessPage = () => {
    const {t} = useTranslation()
    const matches = useMediaQuery('(min-width:600px)')

    return (
        <WithBackground bg={matches ? '/assets/images/bkg-soundtrack.png' : '/assets/images/bkg-soundtrack-mobile.png'}>
            <Box margin={'auto'}>
                <Stack alignItems={'center'} px={4} spacing={2}>
                    <img src={'/assets/images/titolo-conv.png'}
                         width={'70%'}/>
                    <Typography textAlign={'center'} variant={'h5'} maxWidth={{xs: '200px', sm:'340px'}}>
                        <Trans i18nKey={'soundtrack.success'} components={{1: <Typography gutterBottom variant={'h4'} fontWeight={'bold'}/>}}/>
                    </Typography>
                </Stack>
            </Box>
        </WithBackground>
    );
}
