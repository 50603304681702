import {Box, CircularProgress, Typography} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import useAxios from "axios-hooks";
import {API_URL, mezzoTrasportoValues} from "../config";
import {useTranslation} from "react-i18next";
import FormContainer from "../components/FormContainer";
import {useEffect, useState} from "react";
import {useDataStore} from "../state/state";
import dayjs from "dayjs";

const Register = () => {
    const {id} = useParams()
    const {initialize} = useDataStore()

    const {t, i18n} = useTranslation()
    const navigate = useNavigate()

    const [{data, loading, error}, refetch] = useAxios(`${API_URL}profile/${id}`)
    const [errorCategoria, setErrorCategoria] = useState(false)

    //const data = {nome: "Matilde", cognome: 'Ugolini', richiestaDocumenti: 'si'}
    const _data = {
        nome: "Matilde",
        cognome: "Ugolini",
        email: "matilde@plesh.co",

        categoria: "DIPENDENTI",

        /*indirizzo: "Indirizzo di residenza",
        cap: "10123",
        provincia: "provincia",
        citta: "citta",
        paese: "paese",
        luogoNascita: "IluogoNascita",
        dataNascita: null,*/
        docTipologia: "",
        docNumero: "Numero del documento",
        docRilasciatoDa: "docRilasciatoDa",

        richiestaDocumenti: 'no',
        cittaPartenzaArrivo: "cittaPartenza",
        dataPartenza: dayjs(),
        dataRitorno: dayjs(),
        mezzoTrasporto: mezzoTrasportoValues[5]?.value,
    }

    useEffect(() => {
        if (data)
            initialize(data)
    }, [data])

    useEffect(() => {
        if (data) {
            if (data.categoria !== 'DIPENDENTI' && data.categoria !== 'AGENTI RMA') {
                //console.log("data.categoria:", data.categoria)
                setErrorCategoria(true)
            } else {
                if (!!data.lang) {
                    i18n.changeLanguage(data?.lang || 'it')
                }
            }/*else if(!!data.partecipazione) {
                navigate('/success')
            }*/
        }

    }, [data])

    return (
        <Box margin={"auto"}>
            {
                loading ?
                    <Box sx={{textAlign: 'center'}}>
                        <CircularProgress/>
                    </Box>
                    : (error || errorCategoria) ?
                        <Typography textAlign={'center'}>
                            {t('shared.user-not-available')}
                        </Typography>
                        : data ? <FormContainer/>

                            : null
            }
        </Box>
    )
}

export default Register