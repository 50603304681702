import {Stack, Typography} from "@mui/material";
import Viaggio from "./Viaggio";
import React from "react";
import Biglietti from "./Biglietti";

const Treno = ({userData})  => {
    const biglietto = (!!userData.biglietto1 || !!userData.biglietto2)
    const viaggioDiGruppo = userData?.mezzoTrasportoDiGruppo === true ?? false
    return (
        <Stack>
            <Viaggio userData={userData} mezzoTrasporto={userData.mezzoTrasporto} direzione={'andata'}/>
            {biglietto ?
                <Typography>
                    {/** Treno individuale */}
                    <strong>IMPORTANTE:</strong>
                    <br/>
                    Ti consigliamo di raggiungere la stazione in tempo utile per salire a bordo del treno prenotato.
                </Typography>
                : <Typography>
                    {/** Treno di gruppo */}
                    <strong>IMPORTANTE:</strong>
                    <br/>
                    La presente pagina vale come documento di viaggio.
                    <br/>
                    Dovrai mostrarne una copia in forma cartacea o digitale se richiesto unitamente ad un documento d'identità in corso di validità.
                    <br/>
                    In caso di controllo, se non esibirai questa pagina, il personale ferroviario potrà negare l'acceso ai tornelli e sul treno dove previsti.
                    <br/>
                    Ti consigliamo di raggiungere la stazione in tempo utile per salire a bordo del treno prenotato.

                    <br/>
                    {(() => {
                        switch (userData.andataOperativo2) {
                            case "FR 9716":
                                return <Typography fontWeight={'bold'}>
                                    Per qualsiasi necessità puoi contattare l’assistenza al numero +39 3498305845, attivo solo durante la giornata di evento 12 giugno.
                                </Typography>
                            case "ITALO 9908":
                                return <Typography fontWeight={'bold'}>
                                    Per qualsiasi necessità puoi contattare l’assistenza al numero +39 320 0290634, attivo solo durante la giornata di evento 12 giugno.
                                </Typography>
                            case "ITALO 9912":
                                return <Typography fontWeight={'bold'}>
                                    Per qualsiasi necessità puoi contattare l’assistenza al numero +39 320 0290634, attivo solo durante la giornata di evento 12 giugno.
                                </Typography>
                            default:
                                return null
                        }
                    })()}

                </Typography>}
            <Viaggio userData={userData} mezzoTrasporto={userData.mezzoTrasporto} direzione={'ritorno'}/>
            {biglietto &&
                <Biglietti biglietto1={userData.biglietto1} biglietto2={userData.biglietto2} userId={userData.id}/>}
        </Stack>
    );
}

export default Treno