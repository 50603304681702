import React from "react";
import {
    Box,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {DateField, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {useField} from "formik";
import {Trans, useTranslation} from "react-i18next";
import {mezzoTrasportoValues} from "../../config";
import {find} from "lodash";
import dayjs from "dayjs";

export const AgencyFormField = ({label, ...props}) => {
    const {t} = useTranslation()
    const [field, meta, helpers] = useField(props)

    return (
        <Grid item xs={props.cols ? props.cols.xs : 12} sm={props.cols ? props.cols.sm : 6}>
            {(() => {
                switch (props.type) {
                    case 'divider':
                        return <Box sx={{pt: 2}}>
                            <Divider/>
                        </Box>
                    case 'note':
                        return <Box>
                            <Typography>
                                {label}
                            </Typography>
                        </Box>
                    case 'checkbox':
                        return <FormControl sx={{pt: 2}} fullWidth error={!!(meta.touched && meta.error)}>
                            <FormGroup>
                                <FormControlLabel
                                    sx={{alignItems: 'start'}}
                                    control={<Checkbox sx={{pt: 0}} checked={field.value}
                                                       {...field}
                                                       {...props}
                                                       disabled={props.precompiled}
                                    />} label={t(`fields.${props.id}`)}
                                />
                            </FormGroup>
                            <FormHelperText>{meta.touched && meta.error}</FormHelperText>
                        </FormControl>
                    case 'radiogroup':
                        return <FormControl sx={{pt: 2}} variant="standard"
                                            fullWidth
                                            error={!!(meta.touched && meta.error)}
                                            disabled={props.precompiled}
                        >
                            {label && <Typography id={props.id}>{label}</Typography>}
                            <RadioGroup
                                aria-labelledby={props.id}
                                defaultValue={''}
                                {...field}
                                {...props}
                                disabled={props.precompiled}
                                row
                            >
                                {props.values?.map(v =>
                                    <FormControlLabel value={v.value} key={v.value} control={<Radio/>}
                                                      label={t(`radio.${v.value}`)}/>
                                )}
                            </RadioGroup>
                            <FormHelperText>{meta.touched && meta.error}</FormHelperText>
                        </FormControl>
                    case 'select':
                        return <FormControl variant="standard" fullWidth error={!!(meta.touched && meta.error)}>
                            {label && <Typography id={props.id}>{label}</Typography>}
                            <Select
                                label={label ?? ''}
                                {...field}
                                variant={"filled"}
                                {...props}
                                disabled={props.precompiled}
                                helperText={meta.touched && meta.error}
                            >
                                {props.values?.map(v =>
                                    <MenuItem value={v.value} key={v.value}>{t(`select.${v.value}`)}</MenuItem>
                                )}
                            </Select>
                            <FormHelperText>{meta.touched && meta.error}</FormHelperText>
                        </FormControl>
                    case 'date':
                        return <LocalizationProvider dateAdapter={AdapterDayjs}>
                            {label && <Typography id={props.id}>{t(`fields.${props.id}`)}</Typography>}
                            <DateField
                                variant={"filled"}
                                name={props.id}
                                required={props.required}
                                disabled={props.precompiled}
                                disableFuture={props.disableFuture}
                                disablePast={props.disablePast}
                                id={props.id}
                                sx={{width: '100%'}}
                                format={'DD/MM/YYYY'}
                                value={dayjs(props.formik.values[props.id])}
                                onChange={(value) => {
                                    props.formik.setFieldValue(props.id, value, true);
                                }}
                                error={!!(props.formik.touched[props.id] && props.formik.errors[props.id])}
                                helperText={props.formik.touched[props.id] && props.formik.errors[props.id]}
                                //label={t(`fields.${props.id}`) ?? ''}
                            />
                        </LocalizationProvider>
                    case 'custom':
                        const mezzo = find(mezzoTrasportoValues, ['value', props.formik.values[props.id]])
                        const categoria = props.formik.values.categoria
                        return categoria !== 'AGENTI RMA' && categoria !== 'AGENZIA ITA' ?
                            <Stack>
                                {mezzo ?
                                    <Typography fontWeight={'bold'} textAlign={'center'} color={'primary'}
                                                variant={'h6'}>
                                        {t('fields.note-mezzoTrasporto')}
                                        <br/>
                                        <Trans i18nKey={`fields.${mezzo.value}`}/>
                                    </Typography> : null}
                            </Stack>
                            : mezzo ?
                                (mezzo.value !== 'MEZZI PROPRI' && mezzo.value !== 'MEZZI PROPRI - OPZ VIAGGIO ORGANIZZATO') ?
                                    <Stack>
                                        <FormControl variant="standard" fullWidth
                                                     error={!!(meta.touched && meta.error)}>
                                            {t('fields.note-mezzoTrasporto')}
                                            {!props.precompiled ?
                                                <Select
                                                    label={label ?? ''}
                                                    variant={"filled"}
                                                    defaultValue={mezzo.value}
                                                    disabled={props.precompiled}
                                                    onChange={(event) => {
                                                        props.formik.setFieldValue('rma-mezzi-propri', event.target.value === "rma-mezzi-propri" ? 'si' : 'no')
                                                    }}
                                                    helperText={meta.touched && meta.error}
                                                >
                                                    <MenuItem value={mezzo.value}
                                                              key={mezzo.value}>{t(`fields.${mezzo.value}`)}</MenuItem>
                                                    <MenuItem value={'rma-mezzi-propri'}
                                                              key={'rma-mezzi-propri'}>{t(`fields.rma-mezzi-propri`)}</MenuItem>
                                                </Select>
                                                : <Stack>
                                                    {
                                                        props.formik.values['rma-mezzi-propri'] === 'si'
                                                            ? 'MEZZI PROPRI' :  t(`fields.${mezzo.value}`)
                                                    }
                                                </Stack>
                                            }
                                            <FormHelperText>{meta.touched && meta.error}</FormHelperText>
                                        </FormControl>
                                    </Stack>
                                    : <Typography fontWeight={'bold'} textAlign={'center'} color={'primary'}
                                                  variant={'h6'}>
                                        {t('fields.note-mezzoTrasporto')}
                                        <br/>
                                        <Trans i18nKey={`fields.${mezzo.value}`}/>
                                    </Typography> : null
                    default:
                        return <>
                            {label && <Typography id={props.id}>{label}</Typography>}
                            <TextField
                                error={!!(meta.touched && meta.error)}
                                helperText={meta.touched && meta.error}
                                {...field}
                                {...props}
                                disabled={props.precompiled}
                                multiline={props.longText}
                                minRows={props.longText ? 3 : 1}
                                variant={"filled"}
                                //variant={props.longText ? "outlined" : "standard"}
                                //label={t(`fields.${props.id}`) ?? ''}
                                sx={{width: '100%'}}/>
                            </>
                }
            })()}
        </Grid>
    )
}
