import {Box} from "@mui/material";
import {LanguageSwitcher} from "./LanguageSwitcher";
import {createTheme, ThemeProvider} from "@mui/material/styles";

export const theme = createTheme({
    palette: {
        mode: 'light'
    }
},);

const Header = () => {
    return (
        <ThemeProvider theme={theme}>
        <Box px={6} py={1}
             zIndex={1000}
             position={'sticky'} top={0}
             display={'flex'} justifyContent={'space-between'} alignItems={'center'}
             sx={{background: 'white'}}
        >
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <img src={"/assets/images/logo-reale-mutua.png"} width={'80px'}/>
            </Box>
            <LanguageSwitcher/>
        </Box>
        </ThemeProvider>
    );
}

export default Header