import './App.css';
import {Route, Routes} from "react-router-dom";
import Register from "./pages/Register";
import {SuccessPage} from "./components/SuccessPage";
import Header from "./components/Header";
import WithBackground from "./components/WithBackground";
import {Box, Stack} from "@mui/material";
import RegisterAgency from "./pages/RegisterAgency";
import Soundtrack from "./components/Soundtrack";
import {SoundtrackSuccessPage} from "./components/SoundtrackSuccessPage";
import ClosedSubscriptions from "./components/ClosedSubscriptions";
import React from "react";
import Logistic from "./pages/Logistic";
import Survey from "./pages/Survey";
import EcoForm from "./pages/EcoForm";

function App() {
    return (
        <div>
            <Header/>
            <Routes>
                <Route path={'/*'} element={<WithBackground children={
                    <Box margin={'auto'}>
                            <Stack alignItems={'center'}>
                                <img src={'/assets/images/titolo-conv.png'}
                                     width={'70%'}/>
                            </Stack>
                    </Box>
                }/>}/>
                <Route path={'/register/:id'} element={<ClosedSubscriptions/>}/>
                <Route path={'/profile/:id'} element={<Register/>}/>
                <Route path={'/logistic/:id'} element={<Logistic/>}/>
                <Route path={'/mobility'} element={<ClosedSubscriptions survey/>}/>
                <Route path={'/agencies/:id'} element={<RegisterAgency/>}/>
                <Route path={'/profile/agencies/:id'} element={<RegisterAgency/>}/>
                <Route path={'/playwithus/:id'} element={<ClosedSubscriptions playWithUs/>}/>
                <Route path={'/playwithus/success'} element={<SuccessPage/>}/>
                <Route path={'/soundtrack/:id'} element={<Soundtrack/>}/>
                <Route path={'/soundtrack/success'} element={<SoundtrackSuccessPage/>}/>
                <Route path={'/survey'} element={<ClosedSubscriptions survey/>}/>
                {/*<Route path={'/survey/:id'} element={<Survey/>}/>*/}

                {/*<Route element={<SuccessPage/>} path={'/success'}/>
                <Route element={<Error/>} path={'/error'}/>*/}
            </Routes>
        </div>
    );
}

export default App;
