import {Stack, Typography} from "@mui/material";
import {hotels, mezzoTrasportoValues} from "../../config";
import {winstonTheme} from "../../theme/winstonTheme";

const Pernotto = ({userData}) => {
    return (
        <Stack>
            <Typography variant={'h6'} fontWeight={'bold'} color={'primary'}>
                SISTEMAZIONE ALBERGHIERA
            </Typography>
            <Typography gutterBottom>
                Abbiamo previsto per te una camera doppia uso singolo presso l’hotel <strong>{hotels[userData.hotel]?.label}</strong>
            </Typography>
            {userData.mezzoTrasporto === 'MEZZI PROPRI' && <Typography gutterBottom>
                Indirizzo: <a href={hotels[userData.hotel]?.map} target={"_blank"} style={{color: winstonTheme.palette.primary.main}}>{hotels[userData.hotel]?.indirizzo}</a>
            </Typography>}
            <br/>
            <Typography gutterBottom>
                Check-In: <strong>{new Date(userData.dataPartenza)?.toLocaleDateString()}</strong>, dalle ore 15.00
            </Typography>
            <Typography gutterBottom>
                Check-Out: <strong>{new Date(userData.dataRitorno)?.toLocaleDateString()}</strong>
            </Typography>
            <br/>
            <Typography>
                Previsto trattamento con solo prima colazione.
                <br/>
                Ricordiamo che gli extra personali saranno da saldare al check-out.
            </Typography>
        </Stack>
    )
}

export default Pernotto