export const formMapAgentiItaliana = [
    {
        id: "agenziaInfo",
        title: "ECCO LA TUA AREA PERSONALE.",
        subtitle: "Compila o completa i tuoi dati entro le ore 15:00 (CEST) del 2/5/2024.",
        numeroInviti: "L'agenzia dispone di ",
        readOnly: true,
        fields: [
            {
                id: 'aziendaAgenzia',
                name: 'aziendaAgenzia',
                label: 'Nome Agenzia',
                type: 'text',
                precompiled: true,
                params: {
                    required: "Campo obbligatorio"
                },
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'ragioneSociale',
                name: 'ragioneSociale',
                label: 'Ragione Sociale',
                type: 'text',
                precompiled: true,
                params: {
                    required: "Campo obbligatorio"
                },
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'codiceAgenzia',
                name: 'codiceAgenzia',
                label: 'Codice Agenzia',
                type: 'email',
                validations: ['string', "required"],
                required: true,
                precompiled: true,
                params: {
                    required: "Required Field"
                },
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'email',
                name: 'email',
                label: 'email',
                type: 'email',
                validations: ['string', "required"],
                required: true,
                precompiled: true,
                params: {
                    required: "Required Field"
                },
                cols: {
                    xs: 12,
                    sm: 6
                }
            }
        ]
    },
    {
        id: "travelInfo",
        title: "VIAGGI E TRASFERIMENTI",
        subtitle: "Il viaggio è uguale per tutti gli agenti iscritti",
        readOnly: true,
        fields: [
            {
                id: 'cittaPartenzaArrivo',
                name: 'cittaPartenzaArrivo',
                label: 'Città di partenza e rientro',
                type: 'text',
                precompiled: true,
                params: {
                    required: "Required Field"
                },
                cols: {
                    xs: 12,
                    sm: 12
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "lang",
                            value: "it"
                        }
                    ]
                }
            },
            {
                id: 'dataPartenza',
                name: 'dataPartenza',
                label: 'Andata',
                type: 'date',
                precompiled: true,
                params: {
                    required: "Required Field"
                },
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'dataRitorno',
                name: 'dataRitorno',
                label: 'Ritorno',
                type: 'date',
                precompiled: true,
                params: {
                    required: "Required Field"
                },
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'mezzoTrasporto',
                name: 'mezzoTrasporto',
                label: 'Mezzo di trasporto',
                type: 'custom',
                precompiled: false,
                cols: {
                    xs:12,
                    md: 12
                }
            },
            /*
            {
                id: 'mezzoTrasporto',
                name: 'mezzoTrasporto',
                label: 'mezzoTrasporto',
                type: 'text',
                precompiled: true,
                cols: {
                    xs: 12,
                    sm: 12
                }
            },
            {
                id: 'note-mezzoTrasporto-proprio',
                name: 'note-mezzoTrasporto-proprio',
                label: 'note-mezzoTrasporto-proprio',
                type: 'note',
                precompiled: true,
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "mezzoTrasporto",
                            value: "mezzoProprio"
                        }
                    ]
                },
                cols: {
                    xs:12,
                    md: 12
                }
            },
            {
                id: 'note-mezzoTrasporto-organizzato',
                name: 'note-mezzoTrasporto-organizzato',
                label: 'note-mezzoTrasporto-organizzato',
                type: 'note',
                precompiled: true,
                conditions: {
                    type: "or",
                    predicates: [
                        {
                            id: "mezzoTrasporto",
                            value: "aereo"
                        },
                        {
                            id: "mezzoTrasporto",
                            value: "bus"
                        },
                        {
                            id: "mezzoTrasporto",
                            value: "treno"
                        }
                    ]
                },
                cols: {
                    xs:12,
                    md: 12
                }
            },*/
            {
                id: 'travelInfoCorrect',
                name: 'travelInfoCorrect',
                label: 'Confermi i dettagli del viaggio? *',
                type: 'radiogroup',
                required: true,
                validations: ["string", 'required'],
                precompiled: false,
                values: [
                    {
                        value: "si",
                        label: "Si"
                    },
                    {
                        value: "no",
                        label: "No"
                    }
                ],
                params: {
                    required: "Required Field",
                }
            },
            {
                id: "note-2",
                name: "note-2",
                type: 'note',
                label: "* Se i dettagli del viaggio non sono corretti scrivici qui le tue note. Ti daremo riscontro sulla fattibilità della tua richiesta.",
                validations: ["string", 'when'],
                params: {
                    when: [
                        ['travelInfoCorrect'], {
                            is: (travelInfoCorrect) => travelInfoCorrect === "no",
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "travelInfoCorrect",
                            value: "no"
                        }
                    ]
                },
                cols: {
                    xs:12,
                    md: 12
                }
            },
            {
                id: "travelInfoCorrectCorrection",
                name: "travelInfoCorrectCorrection",
                label: "Scrivici qui i dati corretti",
                type: "text",
                longText: true,
                precompiled: false,
                validations: ["string", 'when'],
                params: {
                    when: [
                        ['travelInfoCorrect'], {
                            is: (travelInfoCorrect) => travelInfoCorrect === "no",
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "travelInfoCorrect",
                            value: "no"
                        }
                    ]
                },
                cols: {
                    xs:12,
                    md: 12
                }
            },
        ]
    },
    {
        id: "agente1PersonalInfo",
        title: "Iscrizione Agente 1",
        subtitle: "Inserisci i dati dell'agente che parteciperà all'evento",
        fields: [
            {
                id: 'nome',
                name: 'nome',
                label: 'Nome',
                type: 'text',
                precompiled: false,
                required: true,
                validations: ["string", 'required'],
                params: {
                    required: "Campo obbligatorio"
                },
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'cognome',
                name: 'cognome',
                label: 'Cognome',
                type: 'text',
                precompiled: false,
                required: true,
                validations: ["string", 'required'],
                params: {
                    required: "Campo obbligatorio"
                },
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: "note-1",
                name: "note-1",
                type: 'note',
                label:"* Controlla che il nome inserito sia corretto e completo e sia corrispondente a quello sul documento che userà per viaggiare",
                cols: {
                    xs:12,
                    md: 12
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
            },
            {
                id: "note-1-2",
                name: "note-1-2",
                type: 'note',
                label:"* Controlla che il cognome inserito sia corretto e completo e sia corrispondente a quello sul documento che userà per viaggiare",
                cols: {
                    xs:12,
                    md: 12
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
            },
            /*{
                id: 'personalInfoCorrect',
                name: 'personalInfoCorrect',
                label: 'personalInfoCorrect',
                type: 'radiogroup',
                required: true,
                validations: ["string", 'required'],
                precompiled: false,
                values: [
                    {
                        value: "si",
                        label: "Si"
                    },
                    {
                        value: "no",
                        label: "No"
                    }
                ],
                params: {
                    required: "Campo obbligatorio",
                }
            },
            {
                id: "personalInfoCorrection",
                name: "personalInfoCorrection",
                label: "personalInfoCorrection",
                type: "text",
                longText: true,
                precompiled: false,
                validations: ["string", 'when'],
                params: {
                    when: [
                        ['personalInfoCorrect'], {
                            is: (personalInfoCorrect) => personalInfoCorrect === "no",
                            then: (schema) => schema.required("Campo obbligatorio")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "personalInfoCorrect",
                            value: "no"
                        }
                    ]
                }
            },*/
            {
                id: 'indirizzo',
                name: 'indirizzo',
                label: 'Indirizzo',
                type: 'text',
                precompiled: false,
                validations: ["string", 'when'],
                params: {
                    when: [
                        ['richiestaDocumenti'], {
                            is: (richiestaDocumenti) => richiestaDocumenti === "si",
                            then: (schema) => schema.required("Campo obbligatorio")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
                cols: {
                    xs: 12,
                    sm: 8
                }
            },
            {
                id: 'cap',
                name: 'cap',
                label: 'CAP',
                type: 'number',
                precompiled: false,
                validations: ["string", 'when'],
                params: {
                    when: [
                        ['richiestaDocumenti'], {
                            is: (richiestaDocumenti) => richiestaDocumenti === "si",
                            then: (schema) => schema.required("Campo obbligatorio")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
                cols: {
                    xs: 12,
                    sm: 4
                }
            },
            {
                id: 'provincia',
                name: 'provincia',
                label: 'Provincia',
                type: 'text',
                precompiled: false,
                validations: ["string", 'when'],
                params: {
                    when: [
                        ['richiestaDocumenti'], {
                            is: (richiestaDocumenti) => richiestaDocumenti === "si",
                            then: (schema) => schema.required("Campo obbligatorio")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
                cols: {
                    xs: 12,
                    sm: 4
                }
            },
            {
                id: 'citta',
                name: 'citta',
                label: 'Citta',
                type: 'text',
                precompiled: false,
                validations: ["string", 'when'],
                params: {
                    when: [
                        ['richiestaDocumenti'], {
                            is: (richiestaDocumenti) => richiestaDocumenti === "si",
                            then: (schema) => schema.required("Campo obbligatorio")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
                cols: {
                    xs: 12,
                    sm: 4
                }
            },
            {
                id: 'paese',
                name: 'paese',
                label: 'Paese',
                type: 'text',
                precompiled: false,
                validations: ["string", 'when'],
                params: {
                    when: [
                        ['richiestaDocumenti'], {
                            is: (richiestaDocumenti) => richiestaDocumenti === "si",
                            then: (schema) => schema.required("Campo obbligatorio")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
                cols: {
                    xs: 12,
                    sm: 4
                }
            },
            {
                id: 'luogoNascita',
                name: 'luogoNascita',
                label: 'Luogo di nascita *',
                type: 'text',
                precompiled: false,
                validations: ["string", 'when'],
                params: {
                    when: [
                        ['richiestaDocumenti'], {
                            is: (richiestaDocumenti) => richiestaDocumenti === "si",
                            then: (schema) => schema.required("Campo obbligatorio")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'dataNascita',
                name: 'dataNascita',
                label: 'dataNascita',
                type: 'date',
                precompiled: false,
                validations: ["date", "nullable", 'when', 'typeError', 'max'],
                params: {
                    typeError: "Invalid date",
                    max: [new Date(), "This date cannot be in the future"],
                    when: [
                        ['richiestaDocumenti'], {
                            is: (richiestaDocumenti) => richiestaDocumenti === "si",
                            then: (schema) => schema.required("Campo obbligatorio")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'addressCorrect',
                name: 'addressCorrect',
                label: 'I dati sono corretti? *',
                type: 'radiogroup',
                validations: ["string", 'when'],
                precompiled: false,
                values: [
                    {
                        value: "si",
                        label: "Si"
                    },
                    {
                        value: "no",
                        label: "No"
                    }
                ],
                params: {
                    //required: "Campo obbligatorio",
                    when: [
                        ['richiestaDocumenti'], {
                            is: (richiestaDocumenti) => richiestaDocumenti === "si",
                            then: (schema) => schema.required("Campo obbligatorio")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                }
            },
            {
                id: "addressCorrection",
                name: "addressCorrection",
                label: "Scrivici qui i dati corretti",
                type: "text",
                longText: true,
                precompiled: false,
                validations: ["string", 'when'],
                params: {
                    when: [
                        ['richiestaDocumenti', 'addressCorrect'], {
                            is: (richiestaDocumenti, addressCorrect) => (richiestaDocumenti === 'si' && addressCorrect === "no"),
                            then: (schema) => schema.required("Campo obbligatorio")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        },
                        {
                            id: "addressCorrect",
                            value: "no"
                        }
                    ]
                }
            },
        ]
    },
    {
        id: "contactInfo",
        title: "Informazioni Contatto",
        //subtitle: "sub-contactInfo",
        fields: [
            {
                id: 'prefisso',
                name: 'prefisso',
                label: 'Prefisso',
                type: 'text',
                validations: ['string', "required"],
                required: true,
                precompiled: false,
                params: {
                    required: "Required Field"
                },
                cols: {
                    xs: 12,
                    sm: 3
                }
            },
            {
                id: 'cellulare',
                name: 'cellulare',
                label: 'Cellulare',
                type: 'number',
                validations: ['string', "required"],
                required: true,
                precompiled: false,
                params: {
                    required: "Required Field"
                },
                cols: {
                    xs: 12,
                    sm: 9
                }
            },
        ]
    },
    {
        id: "document",
        title: "Documento",
        subtitle: "Inserisci i dati del documento dell'Agente",
        conditions: {
            type: "and",
            predicates: [
                {
                    id: "richiestaDocumenti",
                    value: "si"
                }
            ]
        },
        fields: [
            {
                id: 'docTipologia',
                name: 'docTipologia',
                label: 'Tipologia di documento',
                type: 'select',
                validations: ["string", 'when'],
                //required: true,
                precompiled: false,
                values: [
                    {
                        value: "cartaDIdentita",
                        label: "Carta d'identità"
                    },
                    {
                        value: "passaporto",
                        label: "Passaporto"
                    }
                ],
                params: {
                    when: [
                        ['richiestaDocumenti'], {
                            is: (richiestaDocumenti) => richiestaDocumenti === "si",
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
            },
            {
                id: 'docNumero',
                name: 'docNumero',
                label: 'Numero del documento',
                type: 'text',
                validations: ["string", 'when'],
                //required: true,
                precompiled: false,
                params: {
                    when: [
                        ['richiestaDocumenti'], {
                            is: (richiestaDocumenti) => richiestaDocumenti === "si",
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
            },
            {
                id: 'docDataRilascio',
                name: 'docDataRilascio',
                label: 'docDataRilascio',
                type: 'date',
                validations: ["date", "nullable", 'typeError', 'max', 'when'],
                //required: true,
                precompiled: false,
                params: {
                    typeError: "Invalid date",
                    max: [new Date(), "This date cannot be in the future"],
                    when: [
                        ['richiestaDocumenti'], {
                            is: (richiestaDocumenti) => richiestaDocumenti === "si",
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'docRilasciatoDa',
                name: 'docRilasciatoDa',
                label: 'Rilasciato da (indicare l\'ente che ha rilasciato il documento) *',
                type: 'text',
                validations: ["string", 'when'],
                //required: true,
                precompiled: false,
                params: {
                    when: [
                        ['richiestaDocumenti'], {
                            is: (richiestaDocumenti) => richiestaDocumenti === "si",
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
            },
            {
                id: 'docDataScadenza',
                name: 'docDataScadenza',
                label: 'docDataScadenza',
                type: 'date',
                validations: ["date", "nullable", 'typeError', 'min', 'when'],
                //required: true,
                precompiled: false,
                params: {
                    typeError: "Invalid date",
                    min: [new Date(), "This date cannot be in the past"],
                    when: [
                        ['richiestaDocumenti'], {
                            is: (richiestaDocumenti) => richiestaDocumenti === "si",
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
        ]
    },
    {
        id: "needsInfo",
        title: "ESIGENZE SPECIFICHE",
        //subtitle: "sub-contactInfo",
        fields: [
            {
                id: 'vegetariano',
                name: 'vegetariano',
                label: 'Vegetariano',
                type: 'radiogroup',
                precompiled: false,
                required: true,
                validations: ["string", 'required'],
                params: {
                    required: "Required Field"
                },
                cols: {
                    xs: 12,
                    sm: 6
                },
                values: [
                    {
                        value: "si",
                        label: "Si"
                    },
                    {
                        value: "no",
                        label: "No"
                    }
                ]
            },
            {
                id: 'vegano',
                name: 'vegano',
                label: 'Vegano',
                type: 'radiogroup',
                precompiled: false,
                required: true,
                validations: ["string", 'required'],
                params: {
                    required: "Required Field"
                },
                cols: {
                    xs: 12,
                    sm: 6
                },
                values: [
                    {
                        value: "si",
                        label: "Si"
                    },
                    {
                        value: "no",
                        label: "No"
                    }
                ],
            },
            {
                id: 'allergie',
                name: 'allergie',
                label: 'Allergie',
                type: 'text',
                precompiled: false,
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'intolleranze',
                name: 'intolleranze',
                label: 'Intolleranze',
                type: 'text',
                precompiled: false,
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'altreEsigenzeAlimentari',
                name: 'altreEsigenzeAlimentari',
                label: 'Altre esigenze alimentari specifiche?  Se si, quali?',
                type: 'text',
                longText: true,
                precompiled: false,
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'altreEsigenze',
                name: 'altreEsigenze',
                label: 'Ha bisogno di assistenza specifica legata a difficoltà fisiche per partecipare all\'evento?',
                type: 'text',
                longText: true,
                precompiled: false,
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
        ]
    },
    {
        id: "agente2",
        title: "Iscrizione Agente 2",
        subtitle: "",
        conditions: {
            type: "and",
            predicates: [
                {
                    id: "numeroInviti",
                    value: "2"
                }
            ]
        },
        fields: [
            {
                id: 'agente2',
                name: 'agente2',
                label: 'Vuoi iscrivere anche un secondo Agente? *',
                type: 'radiogroup',
                required: true,
                precompiled: false,
                values: [
                    {
                        value: "si",
                        label: "Si"
                    },
                    {
                        value: "no",
                        label: "No"
                    }
                ],
                validations: ["string", 'when'],
                params: {
                    when: [
                        ['numeroInviti'], {
                            is: (numeroInviti) => numeroInviti?.toString() === "2",
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "numeroInviti",
                            value: "2"
                        }
                    ]
                },
            }
        ]
    },
    /** AGENTE 2 */
    {
        id: "agente2PersonalInfo",
        title: "",
        subtitle: "Inserisci i dati del secondo agente che parteciperà all'evento",
        conditions: {
            type: "and",
            predicates: [
                {
                    id: "numeroInviti",
                    value: "2"
                },
                {
                    id: "agente2",
                    value: "si"
                }
            ]
        },
        fields: [
            {
                id: 'nome-2',
                name: 'nome-2',
                label: 'Nome',
                type: 'text',
                precompiled: false,
                required: true,
                validations: ["string", 'when'],
                params: {
                    when: [
                        ['numeroInviti', 'agente2'], {
                            is: (numeroInviti, agente2) => numeroInviti?.toString() === "2" && agente2 === 'si',
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "numeroInviti",
                            value: "2"
                        },
                        {
                            id: "agente2",
                            value: "si"
                        }
                    ]
                },
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'cognome-2',
                name: 'cognome-2',
                label: 'Cognome',
                type: 'text',
                precompiled: false,
                validations: ["string", 'when'],
                params: {
                    when: [
                        ['numeroInviti', 'agente2'], {
                            is: (numeroInviti, agente2) => numeroInviti?.toString() === "2" && agente2 === 'si',
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "numeroInviti",
                            value: "2"
                        },
                        {
                            id: "agente2",
                            value: "si"
                        }
                    ]
                },
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: "note-1",
                name: "note-1",
                type: 'note',
                label:"* Controlla che il nome inserito sia corretto e completo e sia corrispondente a quello sul documento che userà per viaggiare",
                cols: {
                    xs:12,
                    md: 12
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        },
                        {
                            id: "agente2",
                            value: "si"
                        }
                    ]
                },
            },
            {
                id: "note-1-2",
                name: "note-1-2",
                type: 'note',
                label:"* Controlla che il cognome inserito sia corretto e completo e sia corrispondente a quello sul documento che userà per viaggiare",
                cols: {
                    xs:12,
                    md: 12
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        },
                        {
                            id: "agente2",
                            value: "si"
                        }
                    ]
                },
            },
            /*{
                id: 'personalInfoCorrect',
                name: 'personalInfoCorrect',
                label: 'personalInfoCorrect',
                type: 'radiogroup',
                required: true,
                validations: ["string", 'required'],
                precompiled: false,
                values: [
                    {
                        value: "si",
                        label: "Si"
                    },
                    {
                        value: "no",
                        label: "No"
                    }
                ],
                params: {
                    required: "Campo obbligatorio",
                }
            },
            {
                id: "personalInfoCorrection",
                name: "personalInfoCorrection",
                label: "personalInfoCorrection",
                type: "text",
                longText: true,
                precompiled: false,
                validations: ["string", 'when'],
                params: {
                    when: [
                        ['personalInfoCorrect'], {
                            is: (personalInfoCorrect) => personalInfoCorrect === "no",
                            then: (schema) => schema.required("Campo obbligatorio")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "personalInfoCorrect",
                            value: "no"
                        }
                    ]
                }
            },*/
            {
                id: 'indirizzo-2',
                name: 'indirizzo-2',
                label: 'Indirizzo',
                type: 'text',
                precompiled: false,
                validations: ["string", 'when'],
                params: {
                    when: [
                        ['numeroInviti', 'agente2', 'richiestaDocumenti'], {
                            is: (numeroInviti, agente2, richiestaDocumenti) => richiestaDocumenti === 'si' && numeroInviti?.toString() === "2" && agente2 === 'si',
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "numeroInviti",
                            value: "2"
                        },
                        {
                            id: "agente2",
                            value: "si"
                        },
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
                cols: {
                    xs: 12,
                    sm: 8
                }
            },
            {
                id: 'cap-2',
                name: 'cap-2',
                label: 'CAP',
                type: 'number',
                precompiled: false,
                validations: ["string", 'when'],
                params: {
                    when: [
                        ['numeroInviti', 'agente2', 'richiestaDocumenti'], {
                            is: (numeroInviti, agente2, richiestaDocumenti) => richiestaDocumenti === 'si' && numeroInviti?.toString() === "2" && agente2 === 'si',
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "numeroInviti",
                            value: "2"
                        },
                        {
                            id: "agente2",
                            value: "si"
                        },
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
                cols: {
                    xs: 12,
                    sm: 4
                }
            },
            {
                id: 'provincia-2',
                name: 'provincia-2',
                label: 'Provincia',
                type: 'text',
                precompiled: false,
                validations: ["string", 'when'],
                params: {
                    when: [
                        ['numeroInviti', 'agente2', 'richiestaDocumenti'], {
                            is: (numeroInviti, agente2, richiestaDocumenti) => richiestaDocumenti === 'si' && numeroInviti?.toString() === "2" && agente2 === 'si',
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "numeroInviti",
                            value: "2"
                        },
                        {
                            id: "agente2",
                            value: "si"
                        },
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
                cols: {
                    xs: 12,
                    sm: 4
                }
            },
            {
                id: 'citta-2',
                name: 'citta-2',
                label: 'Citta',
                type: 'text',
                precompiled: false,
                validations: ["string", 'when'],
                params: {
                    when: [
                        ['numeroInviti', 'agente2', 'richiestaDocumenti'], {
                            is: (numeroInviti, agente2, richiestaDocumenti) => richiestaDocumenti === 'si' && numeroInviti?.toString() === "2" && agente2 === 'si',
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "numeroInviti",
                            value: "2"
                        },
                        {
                            id: "agente2",
                            value: "si"
                        },
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
                cols: {
                    xs: 12,
                    sm: 4
                }
            },
            {
                id: 'paese-2',
                name: 'paese-2',
                label: 'Paese',
                type: 'text',
                precompiled: false,
                validations: ["string", 'when'],
                params: {
                    when: [
                        ['numeroInviti', 'agente2', 'richiestaDocumenti'], {
                            is: (numeroInviti, agente2, richiestaDocumenti) => richiestaDocumenti === 'si' && numeroInviti?.toString() === "2" && agente2 === 'si',
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "numeroInviti",
                            value: "2"
                        },
                        {
                            id: "agente2",
                            value: "si"
                        },
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
                cols: {
                    xs: 12,
                    sm: 4
                }
            },
            {
                id: 'luogoNascita-2',
                name: 'luogoNascita-2',
                label: 'Luogo di nascita *',
                type: 'text',
                precompiled: false,
                validations: ["string", 'when'],
                params: {
                    when: [
                        ['numeroInviti', 'agente2', 'richiestaDocumenti'], {
                            is: (numeroInviti, agente2, richiestaDocumenti) => richiestaDocumenti === 'si' && numeroInviti?.toString() === "2" && agente2 === 'si',
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "numeroInviti",
                            value: "2"
                        },
                        {
                            id: "agente2",
                            value: "si"
                        },
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'dataNascita-2',
                name: 'dataNascita-2',
                label: 'dataNascita',
                type: 'date',
                precompiled: false,
                validations: ["string", "nullable", 'when', 'typeError', 'max'],
                params: {
                    typeError: "Invalid date",
                    max: [new Date(), "This date cannot be in the future"],
                    when: [
                        ['numeroInviti', 'agente2', 'richiestaDocumenti'], {
                            is: (numeroInviti, agente2, richiestaDocumenti) => richiestaDocumenti === 'si' && numeroInviti?.toString() === "2" && agente2 === 'si',
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "numeroInviti",
                            value: "2"
                        },
                        {
                            id: "agente2",
                            value: "si"
                        },
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'addressCorrect-2',
                name: 'addressCorrect-2',
                label: 'I dati sono corretti? *',
                type: 'radiogroup',
                precompiled: false,
                values: [
                    {
                        value: "si",
                        label: "Si"
                    },
                    {
                        value: "no",
                        label: "No"
                    }
                ],
                validations: ["string", 'when'],
                params: {
                    when: [
                        ['numeroInviti', 'agente2', 'richiestaDocumenti'], {
                            is: (numeroInviti, agente2, richiestaDocumenti) => richiestaDocumenti === 'si' && numeroInviti?.toString() === "2" && agente2 === 'si',
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "numeroInviti",
                            value: "2"
                        },
                        {
                            id: "agente2",
                            value: "si"
                        },
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
            },
            {
                id: "addressCorrection-2",
                name: "addressCorrection-2",
                label: "Scrivici qui i dati corretti",
                type: "text",
                longText: true,
                precompiled: false,
                validations: ["string", 'when'],
                params: {
                    when: [
                        ['numeroInviti', 'agente2', 'richiestaDocumenti', 'addressCorrect-2'], {
                            is: (numeroInviti, agente2, richiestaDocumenti, addressCorrect) => richiestaDocumenti === 'si' && numeroInviti?.toString() === "2" && agente2 === 'si' && addressCorrect === 'no',
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "numeroInviti",
                            value: "2"
                        },
                        {
                            id: "agente2",
                            value: "si"
                        },
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        },
                        {
                            id: "addressCorrect-2",
                            value: "no"
                        }
                    ]
                }
            },
        ]
    },
    {
        id: "contactInfo-2",
        title: "Informazioni Contatto",
        //subtitle: "sub-contactInfo",
        conditions: {
            type: "and",
            predicates: [
                {
                    id: "numeroInviti",
                    value: "2"
                },
                {
                    id: "agente2",
                    value: "si"
                }
            ]
        },
        fields: [
            {
                id: 'prefisso-2',
                name: 'prefisso-2',
                label: 'Prefisso',
                type: 'text',
                //required: true,
                precompiled: false,
                validations: ["string", 'when'],
                params: {
                    when: [
                        ['numeroInviti', 'agente2'], {
                            is: (numeroInviti, agente2) => numeroInviti?.toString() === "2" && agente2 === 'si',
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                cols: {
                    xs: 12,
                    sm: 3
                }
            },
            {
                id: 'cellulare-2',
                name: 'cellulare-2',
                label: 'Cellulare',
                type: 'number',
                //required: true,
                precompiled: false,
                validations: ["string", 'when'],
                params: {
                    when: [
                        ['numeroInviti', 'agente2'], {
                            is: (numeroInviti, agente2) => numeroInviti?.toString() === "2" && agente2 === 'si',
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                cols: {
                    xs: 12,
                    sm: 9
                }
            },
        ]
    },
    {
        id: "document-2",
        title: "Documento",
        subtitle: "Inserisci i dati del documento dell'Agente 2",
        conditions: {
            type: "and",
            predicates: [
                {
                    id: "richiestaDocumenti",
                    value: "si"
                },
                {
                    id: "agente2",
                    value: "si"
                }
            ]
        },
        fields: [
            {
                id: 'docTipologia-2',
                name: 'docTipologia-2',
                label: 'Tipologia di documento',
                type: 'select',
                //required: true,
                precompiled: false,
                values: [
                    {
                        value: "cartaDIdentita",
                        label: "Carta d'identità"
                    },
                    {
                        value: "passaporto",
                        label: "Passaporto"
                    }
                ],
                validations: ["string", 'when'],
                params: {
                    when: [
                        ['numeroInviti', 'agente2', 'richiestaDocumenti'], {
                            is: (numeroInviti, agente2, richiestaDocumenti) => richiestaDocumenti === 'si' && numeroInviti?.toString() === "2" && agente2 === 'si',
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "numeroInviti",
                            value: "2"
                        },
                        {
                            id: "agente2",
                            value: "si"
                        },
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
            },
            {
                id: 'docNumero-2',
                name: 'docNumero-2',
                label: 'Numero del documento',
                type: 'text',
                //required: true,
                precompiled: false,
                validations: ["string", 'when'],
                params: {
                    when: [
                        ['numeroInviti', 'agente2', 'richiestaDocumenti'], {
                            is: (numeroInviti, agente2, richiestaDocumenti) => richiestaDocumenti === 'si' && numeroInviti?.toString() === "2" && agente2 === 'si',
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "numeroInviti",
                            value: "2"
                        },
                        {
                            id: "agente2",
                            value: "si"
                        },
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
            },
            {
                id: 'docDataRilascio-2',
                name: 'docDataRilascio-2',
                label: 'docDataRilascio',
                type: 'date',
                //required: true,
                precompiled: false,
                validations: ["date", "nullable", 'typeError', 'max', 'when'],
                params: {
                    typeError: "Invalid date",
                    max: [new Date(), "This date cannot be in the future"],
                    when: [
                        ['numeroInviti', 'agente2', 'richiestaDocumenti'], {
                            is: (numeroInviti, agente2, richiestaDocumenti) => richiestaDocumenti === 'si' && numeroInviti?.toString() === "2" && agente2 === 'si',
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "numeroInviti",
                            value: "2"
                        },
                        {
                            id: "agente2",
                            value: "si"
                        },
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'docRilasciatoDa-2',
                name: 'docRilasciatoDa-2',
                label: 'Rilasciato da (indicare l\'ente che ha rilasciato il documento) *',
                type: 'text',
                //required: true,
                precompiled: false,
                validations: ["string", 'when'],
                params: {
                    when: [
                        ['numeroInviti', 'agente2', 'richiestaDocumenti'], {
                            is: (numeroInviti, agente2, richiestaDocumenti) => richiestaDocumenti === 'si' && numeroInviti?.toString() === "2" && agente2 === 'si',
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "numeroInviti",
                            value: "2"
                        },
                        {
                            id: "agente2",
                            value: "si"
                        },
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
            },
            {
                id: 'docDataScadenza-2',
                name: 'docDataScadenza-2',
                label: 'docDataScadenza',
                type: 'date',
                params: {
                    typeError: "Invalid date",
                    min: [new Date(), "This date cannot be in the past"],
                    when: [
                        ['numeroInviti', 'agente2', 'richiestaDocumenti'], {
                            is: (numeroInviti, agente2, richiestaDocumenti) => richiestaDocumenti === 'si' && numeroInviti?.toString() === "2" && agente2 === 'si',
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "numeroInviti",
                            value: "2"
                        },
                        {
                            id: "agente2",
                            value: "si"
                        },
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
        ]
    },
    {
        id: "needsInfo-2",
        title: "ESIGENZE SPECIFICHE",
        //subtitle: "sub-contactInfo",
        conditions: {
            type: "and",
            predicates: [
                {
                    id: "numeroInviti",
                    value: "2"
                },
                {
                    id: "agente2",
                    value: "si"
                }
            ]
        },
        fields: [
            {
                id: 'vegetariano-2',
                name: 'vegetariano-2',
                label: 'Vegetariano',
                type: 'radiogroup',
                precompiled: false,
                validations: ["string", 'when'],
                params: {
                    when: [
                        ['numeroInviti', 'agente2'], {
                            is: (numeroInviti, agente2) => numeroInviti?.toString() === "2" && agente2 === 'si',
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                cols: {
                    xs: 12,
                    sm: 6
                },
                values: [
                    {
                        value: "si",
                        label: "Si"
                    },
                    {
                        value: "no",
                        label: "No"
                    }
                ]
            },
            {
                id: 'vegano-2',
                name: 'vegano-2',
                label: 'Vegano',
                type: 'radiogroup',
                precompiled: false,
                validations: ["string", 'when'],
                params: {
                    when: [
                        ['numeroInviti', 'agente2'], {
                            is: (numeroInviti, agente2) => numeroInviti?.toString() === "2" && agente2 === 'si',
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                cols: {
                    xs: 12,
                    sm: 6
                },
                values: [
                    {
                        value: "si",
                        label: "Si"
                    },
                    {
                        value: "no",
                        label: "No"
                    }
                ],
            },
            {
                id: 'allergie-2',
                name: 'allergie-2',
                label: 'Allergie',
                type: 'text',
                precompiled: false,
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'intolleranze-2',
                name: 'intolleranze-2',
                label: 'Intolleranze',
                type: 'text',
                precompiled: false,
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'altreEsigenzeAlimentari-2',
                name: 'altreEsigenzeAlimentari-2',
                label: 'Altre esigenze alimentari specifiche?  Se si, quali?',
                type: 'text',
                longText: true,
                precompiled: false,
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'altreEsigenze-2',
                name: 'altreEsigenze-2',
                label: 'Ha bisogno di assistenza specifica legata a difficoltà fisiche per partecipare all\'evento?',
                type: 'text',
                longText: true,
                precompiled: false,
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
        ]
    },
]