import {create} from "zustand";
import {produce} from 'immer'

export const useDataStore = create((set, get) => ({
    section:0,
    data:{},
    agency:{},
    updateSection: (section) => set( () => ({section: section})),
    update: (id, value) => {
        set(
            produce((draft) => {
                const d = draft.data
                d[id] = value
            })
        )
    },
    initialize: (startData) => set( () => ({data: startData})),
    initializeAgency: (startData) => set( () => ({agency: startData})),
}))
