import {Box, Card, Divider, Grid, Paper, Stack, Typography} from "@mui/material";
import {winstonTheme} from "../../theme/winstonTheme";

const Viaggio = ({userData, mezzoTrasporto, direzione}) => {
    console.log("userData:", userData)
    const dataViaggio = direzione === 'andata' ? new Date(userData.dataPartenza)?.toLocaleDateString()
        : new Date(userData.dataRitorno)?.toLocaleDateString()
    return (
        <Stack mt={1}>
            <Typography gutterBottom variant={'h6'} fontWeight={'bold'} color={'primary'}>VIAGGIO DI {direzione.toUpperCase()} - {dataViaggio}</Typography>
            {
                direzione === 'andata' ?
                    mezzoTrasporto === "BUS" ?
                        <>
                            <Typography>{userData.cittaPartenzaArrivo} - TORINO</Typography>
                            {
                                userData.cittaPartenzaArrivo?.toLowerCase() === "milano" ?
                                    <Typography>
                                        Appuntamento ore <b>09:30</b> in <b>via Marco Ulpio Traiano 18 – 20149 Milano - angolo Via Papini 1</b> dove troverai un assistente (che puoi contattare per qualsiasi necessità al numero <b>+39 347 743 0687 attivo solo durante la giornata di evento 12 giugno</b>) ad accoglierti per il trasferimento organizzato verso la location dell’evento.
                                    </Typography>
                                    : <Typography>
                                        Appuntamento ore <b>05:00</b> in <b>via Puintat 2 (int. 10) 33100 Udine, punto di raccolta fronte “L’ego louge bar”- “Natura sì”</b> dove troverai un assistente (che puoi contattare per qualsiasi necessità al numero <b>+39 347 356 2754 attivo solo durante la giornata di evento 12 giugno</b>) ad accoglierti per il trasferimento organizzato verso la location dell’evento.
                                    </Typography>
                            }
                        </> : null
                    : <Typography>TORINO - {userData.cittaPartenzaArrivo}</Typography>
            }
            <Stack direction={'row'} flexWrap={{xs: "wrap", sm: 'inherit'}}>
                {
                    [1, 2].map((i) => {
                    return !!userData[`${direzione}Operativo${i}`] &&
                        <Stack sx={{width: {xs: "100%", sm: '50%'}}}>
                                <Box m={0.5} p={1} style={{
                                    border: '1px solid ' + winstonTheme.palette.primary.main,
                                    borderRadius: '0.5rem'
                                }}>
                                    {userData[`${direzione}StazionePartenza${i}`]} - {userData[`${direzione}StazioneArrivo${i}`]} ({/*mezzoTrasporto ?? ""*/} {userData[`${direzione}Operativo${i}`]} )
                                    <Typography gutterBottom>
                                        <strong>{userData[`${direzione}OraPartenza${i}`]} - {userData[`${direzione}OraArrivo${i}`]}</strong>
                                    </Typography>
                                    <Typography>
                                        <strong>PNR:</strong> {userData[`${direzione}Pnr${i}`] ?? "-"}
                                    </Typography>
                                    {userData[`${direzione}Carrozza${i === 1 ? "" : "2"}`] &&
                                        <Typography>
                                            <strong>Carrozza:</strong> {userData[`${direzione}Carrozza${i === 1 ? "" : "2"}`]}
                                        </Typography>}
                                    {userData[`${direzione}CarrozzaPosto${i === 1 ? "" : "2"}`] &&
                                        <Typography>
                                            <strong>Posto:</strong> {userData[`${direzione}CarrozzaPosto${i === 1 ? "" : "2"}`]}
                                        </Typography>}
                                </Box>
                        </Stack>
                    })
                }
            </Stack>
        </Stack>
    );
}

export default Viaggio