import {Button, ListItemIcon, Menu, MenuItem, Stack, Typography} from "@mui/material";
import {ConfirmationNumber, Download, OpenInNew} from "@mui/icons-material";
import React, {useState} from "react";
import axios from "axios";
import {API_URL} from "../../config";

const Biglietti = ({biglietto1, biglietto2, userId}) => {

    return (
        <Stack mt={3} direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={2}>
            <Typography>Biglietto viaggio</Typography>
            {biglietto1 &&
                <FileMenu label={"Biglietto 1"} biglietto={biglietto1} fileName={'ticket-1-we-play-4-real'} userId={userId}/>}
            {biglietto2 &&
                <FileMenu label={"Biglietto 2"} biglietto={biglietto2} fileName={'ticket-2-we-play-4-real'} userId={userId}/>}
        </Stack>
    );
}

export default Biglietti

export const FileMenu = ({label, biglietto, _fileName, userId, qr = false}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const fileNameTemp = biglietto.substring(biglietto.lastIndexOf('/') + 1);
    const fileName = fileNameTemp.substring(0, fileNameTemp.indexOf('.'));

    async function handleCheckDownloadTicket() {
        await axios.get(API_URL + "downloaded-ticket/" + userId);
    }

    return (<>
        <Button variant={"contained"} color={"primary"} sx={{fontWeight: "bold", width: qr === true ? 150 : 'inherit'}}
                startIcon={<ConfirmationNumber/>}
            //href={biglietto2}
            //target={'_blank'}
                onClick={handleClick}
            //onClick={() => downloadFile(biglietto2,'ticket-2-we-play-4-real')}
        >
            {label}
        </Button>
        <Menu
            id="demo-customized-menu"
            MenuListProps={{
                "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
        >
            <MenuItem href={biglietto} target={"_blank"} component={"a"} onClick={qr === true ? () => {} : handleCheckDownloadTicket}>
                <ListItemIcon>
                    <OpenInNew/>
                </ListItemIcon>
                Visualizza
            </MenuItem>
            <MenuItem onClick={() => downloadFile(biglietto, fileName, qr === true ? () => {} : handleCheckDownloadTicket)}>
                <ListItemIcon>
                    <Download/>
                </ListItemIcon>
                Scarica
            </MenuItem>
        </Menu>
    </>
    )
}

export function downloadFile(fileUrl, fileName, handleCheckDownloadTicket) {
    const ext = fileUrl.substring(fileUrl.lastIndexOf('.') + 1);
    console.log("fileUrl:", fileUrl)
    console.log("ext:", ext)

    // Effettua una richiesta GET al file
    fetch(fileUrl)
        .then(response => response.blob())
        .then(blob => {
            // Crea un URL oggetto per il blob
            const url = window.URL.createObjectURL(new Blob([blob]));

            // Crea un elemento <a> temporaneo
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${fileName}.${ext}`); // Nome del file da scaricare

            // Aggiungi l'elemento al DOM
            document.body.appendChild(link);

            // Simula il click sul link per avviare il download
            link.click();

            // Rimuovi l'elemento dal DOM
            document.body.removeChild(link);
        })
        .catch(error => {
            console.error('Errore durante il download del file:', error);
        })
        .finally(() => {
            handleCheckDownloadTicket()
        })
}