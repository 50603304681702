import {Box, Stack} from "@mui/material";

export default function WithBackground({bg = '/assets/images/bkg-arrows.png', children}) {
    return (
        <Stack>
            <Box sx={{ backgroundImage: `url(${bg})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                minHeight: '92vh',
                display: 'flex',
                justifyContent: 'center',
                /*backgroundImage: {
                    xs: `url(${sfondo})`
                },*/
                backgroundAttachment: 'fixed',
            }}>
                {children}
            </Box>
        </Stack>
    )
}
