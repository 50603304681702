import {Stack, Typography} from "@mui/material";
import Viaggio from "./Viaggio";
import React from "react";
import Biglietti from "./Biglietti";

const Volo = ({userData}) => {
    const biglietto = (!!userData.biglietto1 || !!userData.biglietto2)
    return (
        <Stack>
            {(userData?.andataOperativo1 || userData?.andataOperativo2) &&
                <Viaggio userData={userData} mezzoTrasporto={userData.mezzoTrasporto} direzione={'andata'}/>}

            {userData.mezzoTrasportoDiGruppo !== true ? <Typography>
                <b>IMPORTANTE:</b>
                <br/>
                Ti consigliamo di raggiungere l'aeroporto in tempo utile per effettuare le operazioni di check-in.
            </Typography>
                : <>
                    {// andataOperativo1
                        <Stack>
                            {(() => {
                                switch (userData.andataOperativo1) {
                                    case "AZ 1588":
                                    case "AZ 1268":
                                    case "AZ 1736":
                                    case "AZ 1776":
                                        return <>
                                            <Typography>
                                                <b>IMPORTANTE:</b>
                                                <br/>
                                                Ti consigliamo di raggiungere l'aeroporto 2 ore prima e presentarti al banco del check-in
                                                del tuo volo per ritirare le tue carte d’imbarco, <u>compresa la tratta da Roma Fiumicino a Torino Caselle.</u>
                                                <br/>
                                                All’arrivo all’aeroporto di Roma Fiumicino, non uscire dal Terminal e recati direttamente al gate del volo.
                                                <br/>
                                                Per qualsiasi necessità, a Roma Fiumicino, puoi contattare le assistenti ai numeri <b>+39 333 352 3019 / +39 347 597 7893</b>,
                                                disponile solo durante la giornata del 12 giugno.
                                            </Typography>
                                        </>
                                    default:
                                        return null
                                }
                            })()}
                        </Stack>}
                    {// andataOperativo2
                        <Stack>
                            <br/>
                            {(() => {
                                switch (userData.andataOperativo2) {
                                    case "AZ 8908":
                                        return <>
                                            <Typography>
                                                <b>IMPORTANTE:</b>
                                                <br/>
                                                Ti consigliamo di raggiungere l'aeroporto 2 ore prima dell’orario di decollo del tuo volo.
                                                <br/>
                                                <b>APPUNTAMENTO:</b> Terminal 1 – banco check-in del tuo volo dove ti verrà consegnata la carta d’imbarco.
                                                <br/>
                                                Per qualsiasi necessità puoi contattare le assistenti ai numeri <b>+39 333 352 3019 / +39 347 597 7893</b>,
                                                disponile solo durante la giornata del 12 giugno.
                                            </Typography>
                                        </>
                                    case "XZ 2520":
                                        return <>
                                            <Typography>
                                                <b>IMPORTANTE:</b>
                                                <br/>
                                                Ti consigliamo di raggiungere l'aeroporto 2 ore prima,  e presentarti al
                                                banco del check-in del tuo volo dove troverai un’assistente dedicata per ritirare la tua carta d’imbarco.
                                                <br/>
                                                Per la tratta di ritorno, nella sezione "Biglietto viaggio" trovi la tua carta d’imbarco.
                                            </Typography>
                                        </>
                                    case "AZ 1421":
                                    case "AZ 1638":
                                        return <>
                                            <Typography>
                                                <b>IMPORTANTE:</b>
                                                <br/>
                                                Ti consigliamo di raggiungere l'aeroporto 2 ore prima e presentarti al
                                                banco del check-in del tuo volo per ritirare la tua carta d’imbarco
                                            </Typography>
                                        </>
                                    case "FR 8341":
                                    case "FR 8826":
                                    case "FR 928":
                                        return <>
                                            <Typography>
                                                <b>IMPORTANTE:</b>
                                                <br/>
                                                Ti consigliamo di raggiungere l'aeroporto 2 ore prima, nella sezione "Biglietto viaggio" trovi la tua carta d’imbarco.
                                            </Typography>
                                        </>
                                    default:
                                        return null
                                }
                            })()}
                        </Stack>}
                </>
            }
            <br/>
            <InfoBagaglio userData={userData}/>
            <br/>
            {(userData?.ritornoOperativo1 || userData?.ritornoOperativo2) &&
                <Viaggio userData={userData} mezzoTrasporto={userData.mezzoTrasporto} direzione={'ritorno'}/>}

            {biglietto &&
                <Biglietti biglietto1={userData.biglietto1} biglietto2={userData.biglietto2} userId={userData.id}/>}
        </Stack>
    );
}

export default Volo


const InfoBagaglio = ({userData}) => {
    return (<Stack>
        <Typography>
            <b>INFORMAZIONI BAGAGLIO:</b>
            <br/>
            In cabina: <b>1 bagaglio a mano di peso non superiore ai 5 kg</b> e dimensioni massime di <b>55x40x20 cm</b> (comprese maniglie, tasche laterali e rotelle).
            <br/><br/>
            La franchigia bagaglio è rigorosamente conteggiata a persona: non è quindi in alcun modo né cumulabile né trasferibile per più passeggeri che viaggiano insieme.
            <br/>
            Eventuali extra bagaglio dovranno essere pagati al momento del check-in.
            <br/>
        </Typography>
    </Stack>);
}
