export const profileMapAgentiItaliana = [
        {
            id: "personalInfo",
            title: "Informazioni Generali",
            subtitle: "sub-personalInfo",
            fields: [
                {
                    id: 'nome',
                    name: 'nome',
                    label: 'Nome',
                    type: 'text',
                    precompiled: true,
                    params: {
                        required: "Required Field"
                    },
                    cols: {
                        xs: 12,
                        sm: 6
                    }
                },
                {
                    id: 'cognome',
                    name: 'cognome',
                    label: 'Cognome',
                    type: 'text',
                    precompiled: true,
                    params: {
                        required: "Required Field"
                    },
                    cols: {
                        xs: 12,
                        sm: 6
                    }
                },
                {
                    id: "note-1",
                    name: "note-1",
                    type: 'note',
                    label:"Controlla che il cognome inserito sia corretto e completo e sia corrispondente a quello sul documento che userai per viaggiare",
                    cols: {
                        xs:12,
                        md: 12
                    },
                    conditions: {
                        type: "and",
                        predicates: [
                            {
                                id: "richiestaDocumenti",
                                value: "si"
                            }
                        ]
                    },
                },
                {
                    id: "note-1-2",
                    name: "note-1-2",
                    type: 'note',
                    label:"Controlla che il cognome inserito sia corretto e completo e sia corrispondente a quello sul documento che userai per viaggiare",
                    cols: {
                        xs:12,
                        md: 12
                    },
                    conditions: {
                        type: "and",
                        predicates: [
                            {
                                id: "richiestaDocumenti",
                                value: "si"
                            }
                        ]
                    },
                },
                {
                    id: 'indirizzo',
                    name: 'indirizzo',
                    label: 'Indirizzo',
                    type: 'text',
                    precompiled: false,
                    validations: ["string", 'when'],
                    params: {
                        when: [
                            ['richiestaDocumenti'], {
                                is: (richiestaDocumenti) => richiestaDocumenti === "si",
                                then: (schema) => schema.required("Required Field")
                            }
                        ]
                    },
                    conditions: {
                        type: "and",
                        predicates: [
                            {
                                id: "richiestaDocumenti",
                                value: "si"
                            }
                        ]
                    },
                    cols: {
                        xs: 12,
                        sm: 8
                    }
                },
                {
                    id: 'cap',
                    name: 'cap',
                    label: 'CAP',
                    type: 'number',
                    precompiled: false,
                    validations: ["string", 'when'],
                    params: {
                        when: [
                            ['richiestaDocumenti'], {
                                is: (richiestaDocumenti) => richiestaDocumenti === "si",
                                then: (schema) => schema.required("Required Field")
                            }
                        ]
                    },
                    conditions: {
                        type: "and",
                        predicates: [
                            {
                                id: "richiestaDocumenti",
                                value: "si"
                            }
                        ]
                    },
                    cols: {
                        xs: 12,
                        sm: 4
                    }
                },
                {
                    id: 'provincia',
                    name: 'provincia',
                    label: 'Provincia',
                    type: 'text',
                    precompiled: false,
                    validations: ["string", 'when'],
                    params: {
                        when: [
                            ['richiestaDocumenti'], {
                                is: (richiestaDocumenti) => richiestaDocumenti === "si",
                                then: (schema) => schema.required("Required Field")
                            }
                        ]
                    },
                    conditions: {
                        type: "and",
                        predicates: [
                            {
                                id: "richiestaDocumenti",
                                value: "si"
                            }
                        ]
                    },
                    cols: {
                        xs: 12,
                        sm: 4
                    }
                },
                {
                    id: 'citta',
                    name: 'citta',
                    label: 'Citta',
                    type: 'text',
                    precompiled: false,
                    validations: ["string", 'when'],
                    params: {
                        when: [
                            ['richiestaDocumenti'], {
                                is: (richiestaDocumenti) => richiestaDocumenti === "si",
                                then: (schema) => schema.required("Required Field")
                            }
                        ]
                    },
                    conditions: {
                        type: "and",
                        predicates: [
                            {
                                id: "richiestaDocumenti",
                                value: "si"
                            }
                        ]
                    },
                    cols: {
                        xs: 12,
                        sm: 4
                    }
                },
                {
                    id: 'paese',
                    name: 'paese',
                    label: 'Paese',
                    type: 'text',
                    precompiled: false,
                    validations: ["string", 'when'],
                    params: {
                        when: [
                            ['richiestaDocumenti'], {
                                is: (richiestaDocumenti) => richiestaDocumenti === "si",
                                then: (schema) => schema.required("Required Field")
                            }
                        ]
                    },
                    conditions: {
                        type: "and",
                        predicates: [
                            {
                                id: "richiestaDocumenti",
                                value: "si"
                            }
                        ]
                    },
                    cols: {
                        xs: 12,
                        sm: 4
                    }
                },
                {
                    id: 'luogoNascita',
                    name: 'luogoNascita',
                    label: 'Luogo di nascita *',
                    type: 'text',
                    precompiled: false,
                    validations: ["string", 'when'],
                    params: {
                        when: [
                            ['richiestaDocumenti'], {
                                is: (richiestaDocumenti) => richiestaDocumenti === "si",
                                then: (schema) => schema.required("Required Field")
                            }
                        ]
                    },
                    conditions: {
                        type: "and",
                        predicates: [
                            {
                                id: "richiestaDocumenti",
                                value: "si"
                            }
                        ]
                    },
                    cols: {
                        xs: 12,
                        sm: 6
                    }
                },
                {
                    id: 'dataNascita',
                    name: 'dataNascita',
                    label: 'Data di nascita *',
                    type: 'date',
                    precompiled: false,
                    validations: ["date", "nullable", 'when', 'typeError', 'max'],
                    params: {
                        typeError: "Invalid date",
                        max: [new Date(), "This date cannot be in the future"],
                        when: [
                            ['richiestaDocumenti'], {
                                is: (richiestaDocumenti) => richiestaDocumenti === "si",
                                then: (schema) => schema.required("Required Field")
                            }
                        ]
                    },
                    conditions: {
                        type: "and",
                        predicates: [
                            {
                                id: "richiestaDocumenti",
                                value: "si"
                            }
                        ]
                    },
                    cols: {
                        xs: 12,
                        sm: 6
                    }
                },
                {
                    id: 'addressCorrect',
                    name: 'addressCorrect',
                    label: 'I dati sono corretti? *',
                    type: 'radiogroup',
                    validations: ["string", 'when'],
                    precompiled: false,
                    values: [
                        {
                            value: "si",
                            label: "Si"
                        },
                        {
                            value: "no",
                            label: "No"
                        }
                    ],
                    params: {
                        //required: "Required Field",
                        when: [
                            ['richiestaDocumenti'], {
                                is: (richiestaDocumenti) => richiestaDocumenti === "si",
                                then: (schema) => schema.required("Required Field")
                            }
                        ]
                    },
                    conditions: {
                        type: "and",
                        predicates: [
                            {
                                id: "richiestaDocumenti",
                                value: "si"
                            }
                        ]
                    }
                },
                {
                    id: "addressCorrection",
                    name: "addressCorrection",
                    label: "Scrivici qui i dati corretti",
                    type: "text",
                    longText: true,
                    precompiled: false,
                    validations: ["string", 'when'],
                    params: {
                        when: [
                            ['richiestaDocumenti', 'addressCorrect'], {
                                is: (richiestaDocumenti, addressCorrect) => (richiestaDocumenti === 'si' && addressCorrect === "no"),
                                then: (schema) => schema.required("Required Field")
                            }
                        ]
                    },
                    conditions: {
                        type: "and",
                        predicates: [
                            {
                                id: "richiestaDocumenti",
                                value: "si"
                            },
                            {
                                id: "addressCorrect",
                                value: "no"
                            }
                        ]
                    }
                },
            ]
        },
    {
        id: "contactInfo",
        title: "Informazioni Contatto",
        //subtitle: "sub-contactInfo",
        fields: [
            {
                id: 'prefisso',
                name: 'prefisso',
                label: 'Prefisso',
                type: 'text',
                validations: ['string', "required"],
                required: true,
                precompiled: false,
                params: {
                    required: "Required Field"
                },
                cols: {
                    xs: 12,
                    sm: 3
                }
            },
            {
                id: 'cellulare',
                name: 'cellulare',
                label: 'cellulare',
                type: 'number',
                validations: ['string', "required"],
                required: true,
                precompiled: false,
                params: {
                    required: "Required Field"
                },
                cols: {
                    xs: 12,
                    sm: 9
                }
            },
        ]
    },
    {
        id: "document",
        title: "Documento",
        subtitle: "sub-document",
        conditions: {
            type: "and",
            predicates: [
                {
                    id: "richiestaDocumenti",
                    value: "si"
                }
            ]
        },
        fields: [
            {
                id: 'docTipologia',
                name: 'docTipologia',
                label: 'Tipologia di documento',
                type: 'select',
                validations: ["string", 'when'],
                //required: true,
                precompiled: false,
                values: [
                    {
                        value: "cartaDIdentita",
                        label: "Carta d'identità"
                    },
                    {
                        value: "passaporto",
                        label: "Passaporto"
                    }
                ],
                params: {
                    when: [
                        ['richiestaDocumenti'], {
                            is: (richiestaDocumenti) => richiestaDocumenti === "si",
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
            },
            {
                id: 'docNumero',
                name: 'docNumero',
                label: 'Numero del documento',
                type: 'text',
                validations: ["string", 'when'],
                //required: true,
                precompiled: false,
                params: {
                    when: [
                        ['richiestaDocumenti'], {
                            is: (richiestaDocumenti) => richiestaDocumenti === "si",
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
            },
            {
                id: 'docDataRilascio',
                name: 'docDataRilascio',
                label: 'Rilasciato il (indicare la data di rilascio) *',
                type: 'date',
                validations: ["date", "nullable", 'typeError', 'max', 'when'],
                //required: true,
                precompiled: false,
                params: {
                    typeError: "Invalid date",
                    max: [new Date(), "This date cannot be in the future"],
                    when: [
                        ['richiestaDocumenti'], {
                            is: (richiestaDocumenti) => richiestaDocumenti === "si",
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'docRilasciatoDa',
                name: 'docRilasciatoDa',
                label: 'Rilasciato da (indicare l\'ente che ha rilasciato il documento) *',
                type: 'text',
                validations: ["string", 'when'],
                //required: true,
                precompiled: false,
                params: {
                    when: [
                        ['richiestaDocumenti'], {
                            is: (richiestaDocumenti) => richiestaDocumenti === "si",
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
            },
            {
                id: 'docDataScadenza',
                name: 'docDataScadenza',
                label: 'Data di scadenza *',
                type: 'date',
                validations: ["date", "nullable", 'typeError', 'min', 'when'],
                //required: true,
                precompiled: false,
                params: {
                    typeError: "Invalid date",
                    min: [new Date(), "This date cannot be in the past"],
                    when: [
                        ['richiestaDocumenti'], {
                            is: (richiestaDocumenti) => richiestaDocumenti === "si",
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
        ]
    },
    {
        id: "needsInfo",
        title: "ESIGENZE SPECIFICHE",
        //subtitle: "sub-contactInfo",
        fields: [
            {
                id: 'vegetariano',
                name: 'vegetariano',
                label: 'Vegetariano',
                type: 'radiogroup',
                precompiled: false,
                required: true,
                validations: ["string", 'required'],
                params: {
                    required: "Required Field"
                },
                cols: {
                    xs: 12,
                    sm: 6
                },
                values: [
                    {
                        value: "si",
                        label: "Si"
                    },
                    {
                        value: "no",
                        label: "No"
                    }
                ]
            },
            {
                id: 'vegano',
                name: 'vegano',
                label: 'Vegano',
                type: 'radiogroup',
                precompiled: false,
                required: true,
                validations: ["string", 'required'],
                params: {
                    required: "Required Field"
                },
                cols: {
                    xs: 12,
                    sm: 6
                },
                values: [
                    {
                        value: "si",
                        label: "Si"
                    },
                    {
                        value: "no",
                        label: "No"
                    }
                ],
            },
            {
                id: 'allergie',
                name: 'allergie',
                label: 'Allergie',
                type: 'text',
                precompiled: false,
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'intolleranze',
                name: 'intolleranze',
                label: 'Intolleranze',
                type: 'text',
                precompiled: false,
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'altreEsigenzeAlimentari',
                name: 'altreEsigenzeAlimentari',
                label: 'Altre esigenze alimentari specifiche?  Se si, quali?',
                type: 'text',
                longText: true,
                precompiled: false,
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'altreEsigenze',
                name: 'altreEsigenze',
                label: 'Ha bisogno di assistenza specifica legata a difficoltà fisiche per partecipare all\'evento?',
                type: 'text',
                longText: true,
                precompiled: false,
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
        ]
    }

]