export const formMap = [
        {
            id: "personalInfo",
            title: "Informazioni Generali",
            subtitle: "sub-personalInfo",
            fields: [
                {
                    id: 'nome',
                    name: 'nome',
                    label: 'Nome',
                    type: 'text',
                    precompiled: true,
                    params: {
                        required: "Required Field"
                    },
                    cols: {
                        xs: 12,
                        sm: 6
                    }
                },
                {
                    id: 'cognome',
                    name: 'cognome',
                    label: 'Cognome',
                    type: 'text',
                    precompiled: true,
                    params: {
                        required: "Required Field"
                    },
                    cols: {
                        xs: 12,
                        sm: 6
                    }
                },
                {
                    id: "note-1",
                    name: "note-1",
                    type: 'note',
                    label:"Controlla che il cognome inserito sia corretto e completo e sia corrispondente a quello sul documento che userai per viaggiare",
                    cols: {
                        xs:12,
                        md: 12
                    },
                    conditions: {
                        type: "and",
                        predicates: [
                            {
                                id: "richiestaDocumenti",
                                value: "si"
                            }
                        ]
                    },
                },
                {
                    id: "note-1-2",
                    name: "note-1-2",
                    type: 'note',
                    label:"Controlla che il cognome inserito sia corretto e completo e sia corrispondente a quello sul documento che userai per viaggiare",
                    cols: {
                        xs:12,
                        md: 12
                    },
                    conditions: {
                        type: "and",
                        predicates: [
                            {
                                id: "richiestaDocumenti",
                                value: "si"
                            }
                        ]
                    },
                },

                {
                    id: 'personalInfoCorrect',
                    name: 'personalInfoCorrect',
                    label: 'personalInfoCorrect',
                    type: 'radiogroup',
                    required: true,
                    validations: ["string", 'required'],
                    precompiled: false,
                    values: [
                        {
                            value: "si",
                            label: "Si"
                        },
                        {
                            value: "no",
                            label: "No"
                        }
                    ],
                    params: {
                        required: "Required Field",
                    }
                },
                {
                    id: "personalInfoCorrection",
                    name: "personalInfoCorrection",
                    label: "personalInfoCorrection",
                    type: "text",
                    longText: true,
                    precompiled: false,
                    validations: ["string", 'when'],
                    params: {
                        when: [
                            ['personalInfoCorrect'], {
                                is: (personalInfoCorrect) => personalInfoCorrect === "no",
                                then: (schema) => schema.required("Required Field")
                            }
                        ]
                    },
                    conditions: {
                        type: "and",
                        predicates: [
                            {
                                id: "personalInfoCorrect",
                                value: "no"
                            }
                        ]
                    }
                },
                {
                    id: 'indirizzo',
                    name: 'indirizzo',
                    label: 'indirizzo',
                    type: 'text',
                    precompiled: false,
                    validations: ["string", 'when'],
                    params: {
                        when: [
                            ['richiestaDocumenti'], {
                                is: (richiestaDocumenti) => richiestaDocumenti === "si",
                                then: (schema) => schema.required("Required Field")
                            }
                        ]
                    },
                    conditions: {
                        type: "and",
                        predicates: [
                            {
                                id: "richiestaDocumenti",
                                value: "si"
                            }
                        ]
                    },
                    cols: {
                        xs: 12,
                        sm: 8
                    }
                },
                {
                    id: 'cap',
                    name: 'cap',
                    label: 'cap',
                    type: 'number',
                    precompiled: false,
                    validations: ["string", 'when'],
                    params: {
                        when: [
                            ['richiestaDocumenti'], {
                                is: (richiestaDocumenti) => richiestaDocumenti === "si",
                                then: (schema) => schema.required("Required Field")
                            }
                        ]
                    },
                    conditions: {
                        type: "and",
                        predicates: [
                            {
                                id: "richiestaDocumenti",
                                value: "si"
                            }
                        ]
                    },
                    cols: {
                        xs: 12,
                        sm: 4
                    }
                },
                {
                    id: 'provincia',
                    name: 'provincia',
                    label: 'provincia',
                    type: 'text',
                    precompiled: false,
                    validations: ["string", 'when'],
                    params: {
                        when: [
                            ['richiestaDocumenti'], {
                                is: (richiestaDocumenti) => richiestaDocumenti === "si",
                                then: (schema) => schema.required("Required Field")
                            }
                        ]
                    },
                    conditions: {
                        type: "and",
                        predicates: [
                            {
                                id: "richiestaDocumenti",
                                value: "si"
                            }
                        ]
                    },
                    cols: {
                        xs: 12,
                        sm: 4
                    }
                },
                {
                    id: 'citta',
                    name: 'citta',
                    label: 'citta',
                    type: 'text',
                    precompiled: false,
                    validations: ["string", 'when'],
                    params: {
                        when: [
                            ['richiestaDocumenti'], {
                                is: (richiestaDocumenti) => richiestaDocumenti === "si",
                                then: (schema) => schema.required("Required Field")
                            }
                        ]
                    },
                    conditions: {
                        type: "and",
                        predicates: [
                            {
                                id: "richiestaDocumenti",
                                value: "si"
                            }
                        ]
                    },
                    cols: {
                        xs: 12,
                        sm: 4
                    }
                },
                {
                    id: 'paese',
                    name: 'paese',
                    label: 'paese',
                    type: 'text',
                    precompiled: false,
                    validations: ["string", 'when'],
                    params: {
                        when: [
                            ['richiestaDocumenti'], {
                                is: (richiestaDocumenti) => richiestaDocumenti === "si",
                                then: (schema) => schema.required("Required Field")
                            }
                        ]
                    },
                    conditions: {
                        type: "and",
                        predicates: [
                            {
                                id: "richiestaDocumenti",
                                value: "si"
                            }
                        ]
                    },
                    cols: {
                        xs: 12,
                        sm: 4
                    }
                },
                {
                    id: 'luogoNascita',
                    name: 'luogoNascita',
                    label: 'luogoNascita',
                    type: 'text',
                    precompiled: false,
                    validations: ["string", 'when'],
                    params: {
                        when: [
                            ['richiestaDocumenti'], {
                                is: (richiestaDocumenti) => richiestaDocumenti === "si",
                                then: (schema) => schema.required("Required Field")
                            }
                        ]
                    },
                    conditions: {
                        type: "and",
                        predicates: [
                            {
                                id: "richiestaDocumenti",
                                value: "si"
                            }
                        ]
                    },
                    cols: {
                        xs: 12,
                        sm: 6
                    }
                },
                {
                    id: 'dataNascita',
                    name: 'dataNascita',
                    label: 'dataNascita',
                    type: 'date',
                    precompiled: false,
                    validations: ["date", "nullable", 'when', 'typeError', 'max'],
                    params: {
                        typeError: "Invalid date",
                        max: [new Date(), "This date cannot be in the future"],
                        when: [
                            ['richiestaDocumenti'], {
                                is: (richiestaDocumenti) => richiestaDocumenti === "si",
                                then: (schema) => schema.required("Required Field")
                            }
                        ]
                    },
                    conditions: {
                        type: "and",
                        predicates: [
                            {
                                id: "richiestaDocumenti",
                                value: "si"
                            }
                        ]
                    },
                    cols: {
                        xs: 12,
                        sm: 6
                    }
                },
                {
                    id: 'addressCorrect',
                    name: 'addressCorrect',
                    label: 'addressCorrect',
                    type: 'radiogroup',
                    validations: ["string", 'when'],
                    precompiled: false,
                    values: [
                        {
                            value: "si",
                            label: "Si"
                        },
                        {
                            value: "no",
                            label: "No"
                        }
                    ],
                    params: {
                        //required: "Required Field",
                        when: [
                            ['richiestaDocumenti'], {
                                is: (richiestaDocumenti) => richiestaDocumenti === "si",
                                then: (schema) => schema.required("Required Field")
                            }
                        ]
                    },
                    conditions: {
                        type: "and",
                        predicates: [
                            {
                                id: "richiestaDocumenti",
                                value: "si"
                            }
                        ]
                    }
                },
                {
                    id: "addressCorrection",
                    name: "addressCorrection",
                    label: "addressCorrection",
                    type: "text",
                    longText: true,
                    precompiled: false,
                    validations: ["string", 'when'],
                    params: {
                        when: [
                            ['richiestaDocumenti', 'addressCorrect'], {
                                is: (richiestaDocumenti, addressCorrect) => (richiestaDocumenti === 'si' && addressCorrect === "no"),
                                then: (schema) => schema.required("Required Field")
                            }
                        ]
                    },
                    conditions: {
                        type: "and",
                        predicates: [
                            {
                                id: "richiestaDocumenti",
                                value: "si"
                            },
                            {
                                id: "addressCorrect",
                                value: "no"
                            }
                        ]
                    }
                },
            ]
        },
    {
        id: "contactInfo",
        title: "Informazioni Contatto",
        //subtitle: "sub-contactInfo",
        fields: [
            {
                id: 'email',
                name: 'email',
                label: 'email',
                type: 'email',
                validations: ['string', "required"],
                required: true,
                precompiled: true,
                params: {
                    required: "Required Field"
                },
                cols: {
                    xs: 12,
                    sm: 12
                }
            },
            {
                id: 'prefisso',
                name: 'prefisso',
                label: 'prefisso',
                type: 'text',
                validations: ['string', "required"],
                required: true,
                precompiled: false,
                params: {
                    required: "Required Field"
                },
                cols: {
                    xs: 12,
                    sm: 3
                }
            },
            {
                id: 'cellulare',
                name: 'cellulare',
                label: 'cellulare',
                type: 'number',
                validations: ['string', "required"],
                required: true,
                precompiled: false,
                params: {
                    required: "Required Field"
                },
                cols: {
                    xs: 12,
                    sm: 9
                }
            },
        ]
    },
    {
        id: "document",
        title: "Documento",
        subtitle: "sub-document",
        conditions: {
            type: "and",
            predicates: [
                {
                    id: "richiestaDocumenti",
                    value: "si"
                }
            ]
        },
        fields: [
            {
                id: 'docTipologia',
                name: 'docTipologia',
                label: 'Tipologia di documento',
                type: 'select',
                validations: ["string", 'when'],
                //required: true,
                precompiled: false,
                values: [
                    {
                        value: "cartaDIdentita",
                        label: "Carta d'identità"
                    },
                    {
                        value: "passaporto",
                        label: "Passaporto"
                    }
                ],
                params: {
                    when: [
                        ['richiestaDocumenti'], {
                            is: (richiestaDocumenti) => richiestaDocumenti === "si",
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
            },
            {
                id: 'docNumero',
                name: 'docNumero',
                label: 'Numero del documento',
                type: 'text',
                validations: ["string", 'when'],
                //required: true,
                precompiled: false,
                params: {
                    when: [
                        ['richiestaDocumenti'], {
                            is: (richiestaDocumenti) => richiestaDocumenti === "si",
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
            },
            {
                id: 'docDataRilascio',
                name: 'docDataRilascio',
                label: 'docDataRilascio',
                type: 'date',
                validations: ["date", "nullable", 'typeError', 'max', 'when'],
                //required: true,
                precompiled: false,
                params: {
                    typeError: "Invalid date",
                    max: [new Date(), "This date cannot be in the future"],
                    when: [
                        ['richiestaDocumenti'], {
                            is: (richiestaDocumenti) => richiestaDocumenti === "si",
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'docRilasciatoDa',
                name: 'docRilasciatoDa',
                label: 'docRilasciatoDa',
                type: 'text',
                validations: ["string", 'when'],
                //required: true,
                precompiled: false,
                params: {
                    when: [
                        ['richiestaDocumenti'], {
                            is: (richiestaDocumenti) => richiestaDocumenti === "si",
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
            },
            {
                id: 'docDataScadenza',
                name: 'docDataScadenza',
                label: 'docDataScadenza',
                type: 'date',
                validations: ["date", "nullable", 'typeError', 'min', 'when'],
                //required: true,
                precompiled: false,
                params: {
                    typeError: "Invalid date",
                    min: [new Date(), "This date cannot be in the past"],
                    when: [
                        ['richiestaDocumenti'], {
                            is: (richiestaDocumenti) => richiestaDocumenti === "si",
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "richiestaDocumenti",
                            value: "si"
                        }
                    ]
                },
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
        ]
    },
    {
        id: "needsInfo",
        title: "needsInfo",
        //subtitle: "sub-contactInfo",
        fields: [
            {
                id: 'vegetariano',
                name: 'vegetariano',
                label: 'vegetariano',
                type: 'radiogroup',
                precompiled: false,
                required: true,
                validations: ["string", 'required'],
                params: {
                    required: "Required Field"
                },
                cols: {
                    xs: 12,
                    sm: 6
                },
                values: [
                    {
                        value: "si",
                        label: "Si"
                    },
                    {
                        value: "no",
                        label: "No"
                    }
                ]
            },
            {
                id: 'vegano',
                name: 'vegano',
                label: 'vegano',
                type: 'radiogroup',
                precompiled: false,
                required: true,
                validations: ["string", 'required'],
                params: {
                    required: "Required Field"
                },
                cols: {
                    xs: 12,
                    sm: 6
                },
                values: [
                    {
                        value: "si",
                        label: "Si"
                    },
                    {
                        value: "no",
                        label: "No"
                    }
                ],
            },
            {
                id: 'allergie',
                name: 'allergie',
                label: 'allergie',
                type: 'text',
                precompiled: false,
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'intolleranze',
                name: 'intolleranze',
                label: 'intolleranze',
                type: 'text',
                precompiled: false,
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'altreEsigenzeAlimentari',
                name: 'altreEsigenzeAlimentari',
                label: 'altreEsigenzeAlimentari',
                type: 'text',
                longText: true,
                precompiled: false,
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'altreEsigenze',
                name: 'altreEsigenze',
                label: 'altreEsigenze',
                type: 'text',
                longText: true,
                precompiled: false,
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
        ]
    },
    {
        id: "travelInfo",
        title: "travelInfo",
        //subtitle: "sub-personalInfo",
        fields: [
            {
                id: 'cittaPartenzaArrivo',
                name: 'cittaPartenzaArrivo',
                label: 'cittaPartenzaArrivo',
                type: 'text',
                precompiled: true,
                params: {
                    required: "Required Field"
                },
                cols: {
                    xs: 12,
                    sm: 12
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "lang",
                            value: "it"
                        }
                    ]
                }
            },
            {
                id: 'dataPartenza',
                name: 'dataPartenza',
                label: 'dataPartenza',
                type: 'date',
                precompiled: true,
                params: {
                    required: "Required Field"
                },
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'dataRitorno',
                name: 'dataRitorno',
                label: 'dataRitorno',
                type: 'date',
                precompiled: true,
                params: {
                    required: "Required Field"
                },
                cols: {
                    xs: 12,
                    sm: 6
                }
            },
            {
                id: 'mezzoTrasporto',
                name: 'mezzoTrasporto',
                label: 'mezzoTrasporto',
                type: 'custom',
                precompiled: false,
                cols: {
                    xs:12,
                    md: 12
                }
            },
            /*
            {
                id: 'mezzoTrasporto',
                name: 'mezzoTrasporto',
                label: 'mezzoTrasporto',
                type: 'text',
                precompiled: true,
                cols: {
                    xs: 12,
                    sm: 12
                }
            },
            {
                id: 'note-mezzoTrasporto-proprio',
                name: 'note-mezzoTrasporto-proprio',
                label: 'note-mezzoTrasporto-proprio',
                type: 'note',
                precompiled: true,
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "mezzoTrasporto",
                            value: "mezzoProprio"
                        }
                    ]
                },
                cols: {
                    xs:12,
                    md: 12
                }
            },
            {
                id: 'note-mezzoTrasporto-organizzato',
                name: 'note-mezzoTrasporto-organizzato',
                label: 'note-mezzoTrasporto-organizzato',
                type: 'note',
                precompiled: true,
                conditions: {
                    type: "or",
                    predicates: [
                        {
                            id: "mezzoTrasporto",
                            value: "aereo"
                        },
                        {
                            id: "mezzoTrasporto",
                            value: "bus"
                        },
                        {
                            id: "mezzoTrasporto",
                            value: "treno"
                        }
                    ]
                },
                cols: {
                    xs:12,
                    md: 12
                }
            },*/
            {
                id: 'travelInfoCorrect',
                name: 'travelInfoCorrect',
                label: 'travelInfoCorrect',
                type: 'radiogroup',
                required: true,
                validations: ["string", 'required'],
                precompiled: false,
                values: [
                    {
                        value: "si",
                        label: "Si"
                    },
                    {
                        value: "no",
                        label: "No"
                    }
                ],
                params: {
                    required: "Required Field",
                }
            },
            {
                id: "note-2",
                name: "note-2",
                type: 'note',
                label: "note-2",
                validations: ["string", 'when'],
                params: {
                    when: [
                        ['travelInfoCorrect'], {
                            is: (travelInfoCorrect) => travelInfoCorrect === "no",
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "travelInfoCorrect",
                            value: "no"
                        }
                    ]
                },
                cols: {
                    xs:12,
                    md: 12
                }
            },
            {
                id: "travelInfoCorrectCorrection",
                name: "travelInfoCorrectCorrection",
                label: "travelInfoCorrectCorrection",
                type: "text",
                longText: true,
                precompiled: false,
                validations: ["string", 'when'],
                params: {
                    when: [
                        ['travelInfoCorrect'], {
                            is: (travelInfoCorrect) => travelInfoCorrect === "no",
                            then: (schema) => schema.required("Required Field")
                        }
                    ]
                },
                conditions: {
                    type: "and",
                    predicates: [
                        {
                            id: "travelInfoCorrect",
                            value: "no"
                        }
                    ]
                },
                cols: {
                    xs:12,
                    md: 12
                }
            },
        ]
    }

]