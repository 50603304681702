import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {winstonTheme} from "./theme/winstonTheme";
import './services/i18n.js'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.Suspense fallback={'loading...'}>
      <React.StrictMode>
          <BrowserRouter>
              <ThemeProvider theme={winstonTheme}>
                  <CssBaseline/>
                    <App />
              </ThemeProvider>
          </BrowserRouter>
      </React.StrictMode>
    </React.Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
