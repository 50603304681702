import React from "react";
import {InputAdornment, TextField} from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import {useTranslation} from "react-i18next";
import {Language} from "@mui/icons-material";

export const LanguageSwitcher = ({color}) => {
    const {t,i18n} = useTranslation()
    return (
        <FormControl sx={{width:'4rem'}} variant={'standard'}>
            <TextField
                select
                //labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={i18n.language}
                label={t('shared.language')}
                onChange={(e) => i18n.changeLanguage(e.target.value)}
                variant={'standard'}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Language />
                        </InputAdornment>
                    ),
                }}
                sx={{color: color ?? 'black',
                    borderColor: color ?? 'black',
                    minWidth: '85px',
                    '&:hover': {borderColor: color ?? 'black'},
                    '&::after': {borderColor: color ?? 'black'},
                    '&::before': {borderColor: color ?? 'black'}}}
            >
                <MenuItem value={'it'}>IT</MenuItem>
                <MenuItem value={'gr'}>GR</MenuItem>
                <MenuItem value={'es'}>ES</MenuItem>
            </TextField>
        </FormControl>
    )
}
