import React from "react";
import {Box, Typography, Grid} from "@mui/material";
import {FormField} from "./FormField";
import {formMap} from "../../assets/data/form-reale";
import {useTranslation} from "react-i18next";
import {winstonTheme} from "../../theme/winstonTheme";
import {every, some} from "lodash";

const Title = ({title, subtitle}) => {
    return (<Box sx={{pb:1, textAlign: 'center', color: winstonTheme.palette.primary.main}}>
        <Typography fontWeight={'bold'} variant={'h5'}>
            {title}
        </Typography>
        {subtitle && <Typography fontWeight={'bold'} variant={'h6'}>
            {subtitle}
        </Typography>}
        <Box my={2} sx={{width:'100%', height:'0.15rem', background: winstonTheme.palette.primary.main}}/>
    </Box>)
}

export const FormContent = ({readOnly, formik}) => {
    const {t} = useTranslation()

    return (
        <Box>
            {formMap?.map((section, index) => {
                const renderSection = () => (
                    <Box mt={2} key={index} sx={{ py: 1 }}>
                        <Title title={t(`sections.${section.id}`)}
                               subtitle={(!readOnly && section.subtitle) ? t(`sections.sub-${section.id}`) : null}
                        />
                        <Grid container spacing={2}>
                            {section.fields.map((field, index) => (
                                shouldRenderField(field) && (
                                    <FormField
                                        formik={formik}
                                        key={index}
                                        name={field.name}
                                        id={field.id}
                                        label={field.label}
                                        type={field.type}
                                        values={field.values ?? []}
                                        cols={field.cols}
                                        precompiled={readOnly || field.precompiled}
                                        longText={field.longText}
                                        disablePast={field.disablePast}
                                        disableFuture={field.disableFuture}
                                        row={field.row}
                                    />
                                )
                            ))}
                        </Grid>
                    </Box>
                );

                const shouldRenderField = (field) => {
                    const resPredicates = field.conditions?.type === 'or' ?
                        some(field.conditions?.predicates, function (o) {
                            return formik.values[o.id] === o.value
                        }) : field.conditions?.type === 'and' ?
                            every(field.conditions?.predicates, function (o) {
                                return formik.values[o.id] === o.value
                            }) : null

                    return (!field.conditions || resPredicates)
            };

                return section.conditions
                    ? shouldRenderField(section) && renderSection()
                    : renderSection();
            })}
        </Box>
    )
}
