import {Stack, Typography, useMediaQuery} from "@mui/material";
import React from "react";
import {Trans, useTranslation} from "react-i18next";
import WithBackground from "./WithBackground";
import {winstonTheme} from "../theme/winstonTheme";

const ClosedSubscriptions = ({playWithUs, survey}) => {
    const {t} = useTranslation()
    const matches = useMediaQuery('(min-width:600px)')
    const bg = playWithUs ?
        matches ? '/assets/images/bkg-lightning.png' : '/assets/images/bkg-lightning-mobile.png'
        : matches ? '/assets/images/bkg-arrows.png' : '/assets/images/bkg-arrows-mobile.png'

    return (
        <WithBackground
            bg={bg}>
            <Stack margin={'auto'} alignItems={'center'} justifyContent={'center'}>
                <Stack mt={{xs: 'auto', sm: 'auto'}} alignItems={'center'}>
                    <img
                        src={matches ? '/assets/images/titolo-conv.png' : '/assets/images/titolo-conv-mobile.png'}
                        width={'60%'}/>
                </Stack>
                <Typography variant={'h4'} textAlign={'center'}>
                    {survey ? <Trans i18nKey={'shared.closed-survey'}/> : t('shared.closed-form')}
                </Typography>
                <Typography variant={'h6'} textAlign={'center'}>
                    <Trans i18nKey={'shared.closed-form-info'} components={{1: <a style={{color: winstonTheme.palette.primary.main}} href={"mailto:info@weplay4real.com"}>info@weplay4real.com</a>}}/>
            </Typography>
            </Stack>
        </WithBackground>
    )
}

export default ClosedSubscriptions